import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SMService } from '../../../services/sm.service';
import { ILoadResult } from '../../../models/ILoadResult';
import { UserProfile } from '../../../models/UserProfile';
import { ProgramMessage } from '../../../models/ProgramMessage';
import { Observable } from 'rxjs';
import { FuseAlertType } from '../../../../@fuse/components/alert';
import { RecConfig } from '../../../models/RecConfig';
import { Router } from '@angular/router';
import { AspectPreferencesDto } from '../../../models/AspectPreferencesDto';
import { IAspect } from '../../../models/IAspect';
import { IAspectPreference } from '../../../models/IAspectPreferencesDto';

@Component({
  selector: 'app-profiledetail',
    templateUrl: './profiledetail.component.html',
    styleUrls: ['./profiledetail.component.css']
})
export class ProfileDetailComponent implements OnInit {
    baseUrl = '';
    public theURL: string;
    public thehttp: HttpClient;
    public profileDetailForm: FormGroup;
    public profileImageForm: FormGroup;
    public loadResults: ILoadResult[];
    public currentResult: string;
    public currentUser: string = "";
    public programMessage: ProgramMessage;
    //@Input() profiledetail: UserProfile;
    public profiledetail: UserProfile;
    editMode: boolean = true;
    public hasProfileImage: boolean = false;
    public isOnboarding: boolean = false;
    public PageTitle: string = "Profile";
    public recconfig: RecConfig;
    public aspectPrefDto: AspectPreferencesDto;
    public aspectPreferences: IAspectPreference[];
    public recconfigLoaded: boolean = false;
    public aspectPrefsLoaded: boolean = false;
    public profileLoaded: boolean = false;

    selectedFiles?: FileList;
    selectedFileNames: string[] = [];
    progressInfos: any[] = [];
    message: string[] = [];
    previews: string[] = [];
    imageInfos?: Observable<any>;
    public hasPreview: boolean = false;

    @Output() onboardingEvent = new EventEmitter<string>();

    alert: { type: FuseAlertType; message: string } = {
        type: 'basic',
        message: 'Trying to Update Profile.  Please Wait...'
    };

    savedalert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: 'Successfully updated profile.'
    };
    showAlert: boolean = false;
    showSavedAlert: boolean = false;

    showBasicInfo: boolean = true;
    showUsageModes: boolean = false;
    showLearningObjectives: boolean = false;
    showAspectsOfInterest: boolean = false;
    showToolbar: boolean = false;
    showContinueButton: boolean = true;
    showAvatarPanel: boolean = false;
    public showTrialStepper: boolean = false;

  constructor(
    http: HttpClient,
    private formBuilder: FormBuilder,
      private smService: SMService,
      private router: Router
    )
    {
    this.baseUrl = environment.BASE_URL;
    this.theURL = this.baseUrl;
    this.thehttp = http;

  }

    ngOnInit(): void {

        //this.profiledetail = this.smService.profiledetail.getValue();

        console.log("ProfileDetailComponent ngOnInit() called.");

        this.profileDetailForm = this.formBuilder.group({
            industry: [""],
            jobfunction: [""],
            timeAvailableForProgram: [""],
            timeAvailablePerDay: [""],
            priorExperience: [""],
            personalityType: [""],
            coachingPreference: [""],
            judgingType: [""],
            primaryMotivation: [""]
        });

        this.profileImageForm = this.formBuilder.group({
            fileInput: new FormControl(),
        });

        /*var y = this.smService.userType.subscribe(response => {
            if (this.smService.IsTrialUser()) {
                this.isOnboarding = true;
                console.log("Onboarding user detected");
            }
        });*/

        var y = this.smService.isOnboarding.subscribe(isOnboarding => {
            if (isOnboarding) {
                //console.log("Onboarding is true.");
                this.isOnboarding = true;
                this.showTrialStepper = true;
            } else {
                //console.log("Onboarding is false.");
                this.isOnboarding = false;
                this.showAvatarPanel = true;
                this.showTrialStepper = false;
            }
        });


        //this.recconfig = this.smService.recconfig;

        //if (this.recconfig == null || this.recconfig == undefined) {
        this.UpdateRecConfig();
        this.GetAspectPreferences();
        //}


        //if (this.profiledetail == null) {
            //console.log("ProfileDetail is null.");
            this.UpdateProfileDetail();
        //} else {
            /*this.profileLoaded = true;
            if (this.profiledetail.imageURL != "" && this.profiledetail.imageURL != undefined) {
                this.hasProfileImage = true;
            }*/

            // For test purposes only
            //this.isOnboarding = true;
            //this.PageTitle = "New User Onboarding";
            
            // For regular use
            /*if (this.profiledetail.userStatus == "Onboarding") {
                this.isOnboarding = true;
                this.PageTitle = "New User Onboarding";
            }*/
        //}



        // Subscribe to programsteam message events
        this.smService.messageReceived.subscribe(result => {

              // Set the programstream to the result
              this.programMessage = result;

              this.UpdateRecConfig();

              this.GetAspectPreferences();

              // Update the ProfileDetail
              this.UpdateProfileDetail();

        }, error => console.error(error));

        //this.scrollToTop();
    }





    BuildProfileForm(): void {

        console.log("Building Profile Form.");

        if (this.profileLoaded) {
            // Set the default values for the form controls
            console.log("Setting default values for Profile Form.");
            this.profileDetailForm.get("timeAvailableForProgram").setValue(this.profiledetail.engTimeAvailable);
            this.profileDetailForm.get("timeAvailablePerDay").setValue(this.profiledetail.engTimePerScenario);
            this.profileDetailForm.get("priorExperience").setValue(this.profiledetail.engPriorExperience);
            this.profileDetailForm.get("personalityType").setValue(this.profiledetail.engPersonalityType);
            this.profileDetailForm.get("coachingPreference").setValue(this.profiledetail.engCoachingPreference);
            this.profileDetailForm.get("judgingType").setValue(this.profiledetail.engJudgingType);
            this.profileDetailForm.get("industry").setValue(this.profiledetail.industry);
            this.profileDetailForm.get("jobfunction").setValue(this.profiledetail.jobFunction);
            this.profileDetailForm.get("primaryMotivation").setValue(this.profiledetail.engPrimaryMotivation);
        }

    }

    BasicContinue() {
        this.showBasicInfo = false;
        this.showUsageModes = true;
        this.SaveProfile();
    }

    UsageModesContinue() {
        this.showUsageModes = false;
        //this.showLearningObjectives = true;
        this.showAspectsOfInterest = true;
        this.SaveProfile();
    }

    LearningObjectivesContinue() {
        this.showLearningObjectives = false;
        this.showAspectsOfInterest = true;
        this.SaveProfile();
    }

    AspectsOfInterestContinue() {
        /*this.showBasicInfo = true;
        this.showUsageModes = true;
        this.showLearningObjectives = true;
        this.showAspectsOfInterest = true;
        this.showContinueButton = false;
        this.showToolbar = true;*/
        this.SaveProfile();

        // If this is the last step in profile creation, we can set this stepper step as complete
        this.smService.onboardingStep.next(1); // 1 = 1st step is completed, move to 2nd step

        // Navigate to the program summary list screen
        this.router.navigate(['admintest', 'layout', 'userprofile', 'programsummarylist']);

    }

    private UpdateRecConfig() {

        this.smService.GetRecConfig().subscribe(result => {

            // Set the user profile info 
            this.recconfig = result.userProfile.recConfig;

            console.log("Loaded Recommendation Configuration for User.");

            this.recconfigLoaded = true;

        }, error => console.error(error));
    }

    private GetAspectPreferences() {
        this.smService.GetAspectPreferences().subscribe(result => {

            // Set the user profile info 
            this.aspectPreferences = result.aspectPreferences;
            this.aspectPrefDto = result;

            console.log("Loaded Aspect (Group) Preferences for User.");

            this.CreateAspectPreferencesForm();

        }, error => console.error(error));
    }

    CreateAspectPreferencesForm() {
        console.log("Creating Aspect Preferences Form.");

        // Create a form control for each aspect group
        this.aspectPreferences.forEach((aspectPref) => {
            this.profileDetailForm.addControl(aspectPref.aspectGroupName, new FormControl(aspectPref.preferenceLevel));
        });

        this.aspectPrefsLoaded = true;
        if (!this.isOnboarding) {
            this.showAspectsOfInterest = true;
        }
    }


    public ShowProfileForm() {

        // User is onboarding and has no profile image
        /*if (this.isOnboarding == true) {
            if (this.hasProfileImage != true) {
                return false;
            }
        }*/
        //this.BuildProfileForm();
        return true;
        //return this.profileLoaded;
    }



    private UpdateProfileDetail() {
        console.log("Updating ProfileDetail.");
        this.smService.GetUserProfile().subscribe(result => {

            // Set the user profile info 
            this.profiledetail = result.userProfile;

            console.log("Retrieved ProfileDetail");

            if (result.userProfile != null) {
                if (result.userProfile.imageURL != "" && result.userProfile.imageURL != undefined) {
                    this.hasProfileImage = true;
                }

                /*if (this.profiledetail.userStatus == "Onboarding") {
                    this.isOnboarding = true;
                    this.PageTitle = "New User Onboarding";
                }*/

                this.profileLoaded = true;
                console.log("ProfileLoaded set to: " + this.profileLoaded);

                this.BuildProfileForm();
            }

        }, error => console.error(error));

    }

    public EditForm() {
        this.editMode = true;
        console.log("Edit Mode set to: " + this.editMode);
    }


    public SaveProfile() {

        // Show the alert
        this.showAlert = true;
        this.showSavedAlert = false;

        console.log("Trying to SaveProfile..");

        // Get the profile detail from the form
        this.profiledetail.engTimeAvailable = this.profileDetailForm.value.timeAvailableForProgram;
        this.profiledetail.engTimePerScenario = this.profileDetailForm.value.timeAvailablePerDay;
        this.profiledetail.engPriorExperience = this.profileDetailForm.value.priorExperience;
        this.profiledetail.engPersonalityType = this.profileDetailForm.value.personalityType;
        this.profiledetail.engCoachingPreference = this.profileDetailForm.value.coachingPreference;
        this.profiledetail.engJudgingType = this.profileDetailForm.value.judgingType;
        this.profiledetail.industry = this.profileDetailForm.value.industry;
        this.profiledetail.jobFunction = this.profileDetailForm.value.jobfunction;
        this.profiledetail.engPrimaryMotivation = this.profileDetailForm.value.primaryMotivation;
        this.profiledetail.basicProfileComplete = true;

        if (this.isOnboarding) {
            this.profiledetail.engagementType = "BasicProfileCompleteEngagement";
        } else {
            this.profiledetail.engagementType = "SubmitsProfileInfo";
        }

        //this.recconfig.coverageWeight = this.profileDetailForm.get("coverageWeight").value;
        //this.recconfig.masteryWeight = this.profileDetailForm.get("masteryWeight").value;
        this.recconfig.userId = this.profiledetail.id;
        this.recconfig.userName = this.profiledetail.name;
        this.recconfig.roundId = this.profiledetail.lastRoundId;

        this.profiledetail.recConfig = this.recconfig;

        // Collect the aspect preferences
        this.aspectPreferences.forEach((aspectPref) => {
            aspectPref.preferenceLevel = this.profileDetailForm.get(aspectPref.aspectGroupName).value;
        });
        this.aspectPrefDto.aspectPreferences = this.aspectPreferences;

        // Save the profile
        this.smService.SubmitProfileInfo(this.profiledetail, this.aspectPrefDto).subscribe(result => {

            if (result == false) {
                console.log("Unable to update user profile.");

                this.ShowProfileUpdateError();

            } else {

                console.log("Successfully updated user profile.");

                // Show the alert
                this.showAlert = false;
                this.showSavedAlert = true;

                // Message the parent to move to the next step in the onboarding process if needed
                // TODO - need to refactor this
                if (this.isOnboarding) {
                    this.onboardingEvent.emit("BasicProfileComplete");
                }

                this.showAvatarPanel = true;

                // also reset the form's edit status
                //this.editMode = false;

                this.smService.profiledetail.next(this.profiledetail);

            }

        },
            (result) => {

                this.ShowProfileUpdateError();

                // Loop through the response errors
                /*forEach(result.error, err => {
                    this.alert.message += " " + err.description + " ";
                    console.log(this.alert.message);
                })*/


            });
    }

    ShowProfileUpdateError(): void {

        // Create alert from the response errors
        this.alert = {
            type: 'error',
            message: 'Something went wrong, please try again.'
        };

        // Show the alert
        this.showAlert = true;
    }

    formatLabel(value: number): string {
        if (value >= 1000) {
            return Math.round(value / 1000) + 'k';
        }

        return `${value}`;
    }

    // Image upload functions --------------------------------------------------
    selectFiles(event: any): void {

        console.log("Called selectFiles()");

        this.message = [];
        this.progressInfos = [];
        this.selectedFileNames = [];
        this.selectedFiles = event.target.files;

        this.previews = [];
        this.hasPreview = false;
        if (this.selectedFiles && this.selectedFiles[0]) {
            const numberOfFiles = this.selectedFiles.length;
            for (let i = 0; i < numberOfFiles; i++) {
                const reader = new FileReader();

                reader.onload = (e: any) => {
                    console.log(e.target.result);
                    this.previews.push(e.target.result);
                    this.hasPreview = true;
                };

                reader.readAsDataURL(this.selectedFiles[i]);

                this.selectedFileNames.push(this.selectedFiles[i].name);
            }
        }
    }

    upload(idx: number, file: File): void {

        console.log("Called upload()");

        this.progressInfos[idx] = { value: 0, fileName: file.name };

        if (file) {
            this.smService.UploadProfileImage(file).subscribe(
                (uploadResponse) => {


                    if (uploadResponse.imageURL != null) {

                        // Reset the form
                        this.message = [];
                        this.progressInfos = [];
                        this.selectedFileNames = [];
                        this.selectedFiles = undefined;
                        this.hasPreview = false;
                        this.imageInfos = this.smService.getFiles();

                        // Update the profile image
                        this.profiledetail.imageURL = uploadResponse.imageURL;

                        this.smService.profiledetail.next(this.profiledetail);

                        this.hasProfileImage = true;
                    }
                },
                (err: any) => {
                    let msg = file.name + ': Failed!';

                    if (err.error && err.error.message) {
                        msg += ' ' + err.error.message;
                    }

                    this.message.push(msg);
                }
            );
        }
    }

    uploadFiles(): void {

        console.log("Called uploadFiles()");

        this.message = [];

        if (this.selectedFiles) {
            for (let i = 0; i < this.selectedFiles.length; i++) {
                this.upload(i, this.selectedFiles[i]);
            }
        }
    }

    public scrollToTop() {
        window.scrollTo(0, 0);
    }

    public ToggleSideBar() {

        // Show or hide the main menu bar
        this.smService.showMainMenu.next(!this.smService.showMainMenu.value);


    }

 }

