import { IAgConfig } from "./IAgConfig";
import { IRecConfig } from "./IRecConfig";
import { UserProfile } from "./UserProfile";

export class RecConfig implements IRecConfig {
    id: string;
    name: string;
    description: string;
    entityType: string;

    aspectGraphWeight: number;
    engagementWeight: number;
    usageIntentionOneWeight: number;
    usageIntentionTwoWeight: number;
    usageIntentionThreeWeight: number;
    priorExperienceWeight: number;
    timeAvailableWeight: number;
    timePerScenarioWeight: number;

    personalityTypeWeight: number;
    coverageWeight: number;
    masteryWeight: number;
    progressWeight: number;
    discoverWeight: number;
    discoverNewnessWeight: number;
    strengthsWeight: number;
    journeyWeight: number;
    priorityWeight: number;

    userName: string;
    userId: string;
    programId: string;
    roundId: string;

    //agconfigs: IAgConfig[];
}

export class ConfigDto {
    recConfig: RecConfig;
    userProfile: UserProfile;
    token?: string;
}

export class RecConfigDto {
    id: string;
    name: string;
    description: string;
    entityType: string;

    aspectGraphWeight: number;
    engagementWeight: number;
    usageIntentionOneWeight: number;
    usageIntentionTwoWeight: number;
    usageIntentionThreeWeight: number;
    priorExperienceWeight: number;
    timeAvailableWeight: number;
    timePerScenarioWeight: number;

    personalityTypeWeight: number;
    coverageWeight: number;
    masteryWeight: number;
    progressWeight: number;
    discoverWeight: number;
    discoverNewnessWeight: number;
    strengthsWeight: number;
    journeyWeight: number;
    priorityWeight: number;

    userId: string;
    userName: string;
    programId: string;
    roundId: string;
}
