import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { Dashboard } from '../../../models/Dashboard';
import { ProgramStream } from '../../../models/ProgramStream';
import { ProgramMessage } from '../../../models/ProgramMessage';
import { ILoadResult } from '../../../models/ILoadResult';
import { SMService } from '../../../services/sm.service';
import { environment } from '../../../../environments/environment';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { forEach } from 'lodash';
import { UserProfile } from '../../../models/UserProfile';

@Component({
  selector: 'app-programstream',
  templateUrl: './programstream.component.html'
})

export class ProgramStreamComponent implements OnInit {
    public theURL: string;
    public thehttp: HttpClient;
    public messageForm: FormGroup;
    public loadResults: ILoadResult[];
    public currentResult: string;
    public currentUser: string = "";
    private continuationToken: string = "";

    public programstream = new ProgramStream();
    public programMessage: ProgramMessage;
    @Input() testmessage: string = "Enter message...";
    @Input() dashboard: Dashboard;
    //@Input() smService: SMService;
    baseUrl = '';

    public showRecorder: boolean = false;
    public videoFile: File;
    public showPreview: boolean = false;
    public videoPreviewUrl: string;
    videoPreviewElement: HTMLVideoElement;
    @ViewChild('videoPreview') videoPreviewElementRef: ElementRef;

    @Input() coachMode: boolean = false;
    @Input() streamForUser: UserProfile;
    @Input() currentUserProfile: UserProfile;
    currentProfile: UserProfile;
    public isOnboarding: boolean = false;
    public showControls: boolean = false;

    @Output() onboardingEvent = new EventEmitter<string>();

    constructor(
        http: HttpClient,
        private formBuilder: FormBuilder,
        private smService: SMService
    )
    {
        this.baseUrl = environment.BASE_URL;
        this.messageForm = this.formBuilder.group({
            messageInput: ['']
        });
        this.theURL = this.baseUrl;
        this.thehttp = http;

    }

    ngOnInit(): void {

        if (this.isOnboarding == false) {
            this.showControls = true;
        }

        // Subscribe to programstream message events
        this.smService.messageReceived.subscribe(result => {

            //console.log("Received Message from Chat Hub");

            // Set the programstream to the result
            this.programMessage = result;

            // Add message to client side programstream
            this.AddNewMessage(this.programMessage);

        }, error => console.error(error));

        this.LoadProgramStream();


    }

    ngOnChanges(changes: SimpleChanges) {
        for (const streamForUser in changes) {
            var chng = changes[streamForUser];
            var cur = chng.currentValue;
            var prev = chng.previousValue;

            if (cur != undefined && prev != undefined) {
                console.log(`${streamForUser}: currentValue = ${cur}, previousValue = ${prev}`);

                if (cur != prev) {
                    this.LoadProgramStream();
                }          

            }

        }
    }

    public LoadProgramStream(): void {

        if (!this.coachMode) {
            this.streamForUser = null;
            this.currentProfile = this.currentUserProfile;
        } else {
            this.currentProfile = this.streamForUser;
        }
        console.log("Loading ProgramStream for user: " + this.currentProfile);
       
        this.smService.GetProgramStream(this.currentProfile, this.continuationToken).subscribe(result => {

            if (result != null && result != undefined) {

                // Set the programstream to the result
                this.programstream = result;

                // test with just 1 message
                //this.programstream.messages = result.messages.slice(0, 1);

                this.continuationToken = this.programstream.continuationToken;

                this.SortMessages();

                if (this.currentProfile != null && this.currentProfile != undefined) {
                    if (this.currentProfile.userStatus == "Onboarding") {
                        this.isOnboarding = true;
                    }
                }

            }


        }, error => console.error(error));
    }

    public LoadMoreProgramStream(): void {
        if (!this.coachMode) {
            this.streamForUser = null;
            this.currentProfile = this.currentUserProfile;
        } else {
            this.currentProfile = this.streamForUser;
        }
        console.log("Loading ProgramStream for user: " + this.currentProfile);

        this.smService.GetProgramStream(this.currentProfile, this.continuationToken).subscribe(result => {

            // Append the result messages to the existing programstream's messages
            this.programstream.messages = this.programstream.messages.concat(result.messages);

            // Reset the continuation token
            this.continuationToken = result.continuationToken;

            this.SortMessages();

        }, error => console.error(error));

    }


    public SendMessage(): void {

        // Get the message from the form
        this.testmessage = this.messageForm.get('messageInput').value;

        // Get a new message object to send
        var msg = this.GetNewProgramMessage();

        // Get any message attachments - the videos
        if (this.videoFile != null) {
            msg.responseFileName = this.videoFile.name;
        }

        // Post the Message to the Server
        console.log("Sending Program Message");
        this.smService.SendProgramMessage(msg, this.videoFile).subscribe(result => {

            console.log("Received Result of Send Program Message");

            // Set the programstream to the result
            //this.programMessage = result;

            // Add message to client side programstream
            //this.programstream.messages.unshift(this.programMessage);

            // also clear the form
            this.messageForm.reset();
            this.ClearMessage();
            this.videoFile = null;

        }, error => console.error(error));

        // Then send the message to the Chat Hub
        var token = this.smService.GetCurrentUserToken();
        console.log("Sending message to chat hub");
        this.smService.thenable.then(() => {
            console.log("Invoking Connection");
            this.smService.connection.invoke("SendMessage", token, msg)
                .then(() => {
                    // This is called after the server has processed the message and acknowledged that it received it.
                    console.log("Sent Message");
                })
                .catch(function (err) {
                    console.error(err.toString());
                });
        }, error => console.error(error));
        console.log("Finished Send Message");

        if (this.isOnboarding) {
            this.onboardingEvent.emit("FirstMessageSent");
            this.showControls = true;
        }

    }

    GetNewProgramMessage(): ProgramMessage {
        // Create a new message object and populate it with the message
        this.programMessage = new ProgramMessage();

        // Deprecated - no longer created on client
        this.programMessage.id = uuidv4(); // Of format '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'

        this.programMessage.name = "New User Message";
        this.programMessage.programId = this.programstream.id;


        // User Info
        var userInfo = this.smService.GetCurrentUser();

        if (this.coachMode) {
            this.programMessage.messageType = "CoachMessage";
            this.programMessage.streamForUserName = this.streamForUser.userName;
            this.programMessage.userId = uuidv4();
            this.programMessage.userName = this.streamForUser.userName;

            // Coach creates a message on behalf of the user so the message is part of the user's stream
            this.programMessage.authorId = userInfo.item;
            this.programMessage.authorName = userInfo.username;

        } else {
            this.programMessage.messageType = "UserMessage";
            this.programMessage.streamForUserName = userInfo.username;
            this.programMessage.userId = uuidv4();
            this.programMessage.userName = userInfo.username;
            this.programMessage.authorId = uuidv4();
            this.programMessage.authorName = userInfo.username;

        }

        this.programMessage.messageDate = new Date();
        this.programMessage.messageNumber = this.GetNextMessageNum();
        this.programMessage.entityType = "ProgramMessage";
        this.programMessage.messageText = this.testmessage;
        this.programMessage.status = "Open";


        /*if (this.currentUserProfile != null) {
            this.programMessage.imageURL = this.currentUserProfile.imageURL;
        }*/

        return this.programMessage;
    }

    public CancelRec(): void {

        // Hide the recorder panel
        this.showRecorder = false;

    }

    private blobToFile(theBlob, fileName) {
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    }

    public UploadRec(uploadVideo: Blob): void {

        // Create and store a file from the blob
        var filename = this.smService.GetCurrentUser().item + "_user_response_" + ".mp4";
        this.videoFile = this.blobToFile(uploadVideo, filename);

        // Create an URL from the video file
        this.videoPreviewUrl = URL.createObjectURL(this.videoFile);
        console.log(this.videoPreviewUrl);

        this.videoPreviewElement = this.videoPreviewElementRef.nativeElement;
        this.videoPreviewElement.src = this.videoPreviewUrl;
        this.videoPreviewElement.width = 75;

        // Hide the recorder panel
        this.showRecorder = false;

        // Show preview of video in message panel instead
        this.showPreview = true;

    }

    public ClearMessage(): void {

        // Clear the preview
        if (this.videoPreviewElement != null) {
            this.videoPreviewElement.src = "";
            this.videoPreviewElement.width = 0;
        }
        this.showPreview = false;
        this.programMessage = null;
    }

    // HELPER FUNCTIONS -----------------------------------------------------------------------------------------------
    GetNextMessageNum(): number {
        return this.programstream.messages.length + 1;
    }

    AddNewMessage(newMsg: ProgramMessage): void {
        this.programstream.messages.unshift(newMsg);

        // Indicate that it's a new message
        newMsg.isNew = true;


        this.SortMessages();  
    }

    RemoveMessage(newMsg: ProgramMessage): void {
        for (var i = 0; i < this.programstream.messages.length; i++) {
            if (this.programstream.messages[i].id == newMsg.id) {
                this.programstream.messages.splice(i, 1); 
                break;
            }
        }
    }

    public SortMessages(): void {
        // Sort the messages by messageNumber in reverse so most recent messages are at the top
        this.programstream.messages.sort((a, b) => (new Date(a.messageDate) < new Date(b.messageDate)) ? 1 : -1);

        // Set any messages that are not new to false
        for (var i = 0; i < this.programstream.messages.length; i++) {
            if (this.programstream.messages[i].isNew != true) {
                this.programstream.messages[i].isNew = false;
            }
        }

    }

    public ShowRecorderPanel(): void {

        // Show the recorder panel
        this.showRecorder = true;

    }

    GetNewRecommendation(): void {

        // Send to Server
        console.log("Getting a New Recommendation: ");

        var progMessage = this.GetNewProgramMessage();
        progMessage.messageText = "Get New Recommendation";

        this.smService.GetNewRecommendation(progMessage).subscribe(result => {

            console.log("Confirmed getting new recommendation.");

            // Onboarding ends when the user gets their first recommendation
            if (this.isOnboarding) {
                this.isOnboarding = false;
                this.onboardingEvent.emit("FirstRecommendationReceived");
            }



        }, error => console.error(error));

    }

    RequestHumanReview(): void {

        // Get the message from the form
        this.testmessage = "Request Human Review";

        // Get a new message object to send
        var msg = this.GetNewProgramMessage();

        // Post the Message to the Server
        console.log("Sending Program Message");
        this.smService.RequestHumanReview(msg).subscribe(result => {

            console.log("Requested Review by Human Coach.");

        }, error => console.error(error));

    }

 }


