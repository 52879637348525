import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { FullDashboardComponent } from './fulldashboard.component';
import { FullDashboardRoutingModule } from './fulldashboard.routing';

@NgModule({
    declarations: [
        FullDashboardComponent
    ],
    imports     : [
        SharedModule,
        FullDashboardRoutingModule
    ],
    exports: [
        FullDashboardComponent
    ]
})
export class FullDashboardModule
{
}
