import { IRubric } from "./IRubric";

export class Rubric implements IRubric {
    id: string;
    name: string;
    description: string;
    entityType: string;
    feedbackType: string;
    handler: string;
    feedbackOutputEntityId: string;
    userId: string;
    programId: string;
    evaluationPrompt: string;
    improvementPrompt: string;
    number: number;
    scenarioId: string;
    scenarioName: string;
    scenarioNum: number;
    intent: string;
    level: string;
    priority: number;
    question: string;
    questionFormat: string;
    type: string;
    notes: string;
    response: string;
    improvement: string;
    score: number;
    maxScore: number;
    responseId: string;
    originalRubricId: string;
}
