import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SMService } from '../../../services/sm.service';
import { ILoadResult } from '../../../models/ILoadResult';
import { UserProfile } from '../../../models/UserProfile';
import { ProgramMessage } from '../../../models/ProgramMessage';
import { Observable } from 'rxjs';


@Component({
    selector: 'app-programsettings',
    templateUrl: './programsettings.component.html',
    styleUrls: ['./programsettings.component.css']
})
export class ProgramSettingsComponent implements OnInit {
    baseUrl = '';
    public theURL: string;
    public thehttp: HttpClient;
    public programSettingsForm: FormGroup;
    public loadResults: ILoadResult[];
    public currentResult: string;
    public currentUser: string = "";
    public programMessage: ProgramMessage;
    @Input() profiledetail: UserProfile;

    public isOnboarding: boolean = false;
    public PageTitle: string = "Program Settings";

    @Output() onboardingEvent = new EventEmitter<string>();

  constructor(
    http: HttpClient,
    private formBuilder: FormBuilder,
    private smService: SMService
    )
    {
    this.baseUrl = environment.BASE_URL;
    this.theURL = this.baseUrl;
    this.thehttp = http;

  }

  ngOnInit(): void {


      if (this.profiledetail == null) {

          this.UpdateProfileDetail();

      } else {

          if (this.profiledetail.userStatus == "Onboarding") {
              this.isOnboarding = true;
              this.PageTitle = "New User Onboarding";
          }
      }


      this.programSettingsForm = this.formBuilder.group({
      });

    }

    private UpdateProfileDetail() {

        this.smService.GetUserProfile().subscribe(result => {

            // Set the user profile info 
            this.profiledetail = result.userProfile;

            if (result.userProfile != null) {

                if (this.profiledetail.userStatus == "Onboarding") {
                    this.isOnboarding = true;
                    this.PageTitle = "New User Onboarding";
                }
            }

        }, error => console.error(error));

    }


    public SaveProgramSettings() {

        console.log("Trying to Save Program Settings..");

        // Get the profile detail from the form


        if (this.isOnboarding) {
            this.onboardingEvent.emit("ProgramSettingsComplete");
        }


        // TODO Save the profile
        /*this.smService.SubmitProgramSettings().subscribe(result => {

            if (result == false) {
                console.log("Unable to update program settings.");
            } else {

                console.log("Successfully updated program settings.");

                // Message the parent to move to the next step in the onboarding process if needed
                if (this.isOnboarding) {
                    this.onboardingEvent.emit("ProgramSettingsComplete");
                }


                // also reset the form's edit status
                //this.editMode = false;
            }

        }, error => console.error(error));*/
    }

    formatLabel(value: number): string {
        if (value >= 1000) {
            return Math.round(value / 1000) + 'k';
        }

        return `${value}`;
    }

 }

