import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes, AppRoutingModule } from 'app/app.routing';
import { AdmintestComponent } from './layout/layouts/admintest/admintest.component';


const routerConfig: ExtraOptions = {
    //preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    //enableTracing: true
};

@NgModule({
    declarations: [
        AppComponent,
        AdmintestComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        //RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // App routes - must come after any modules with child routers
        AppRoutingModule,

        // Layout module of your application
        LayoutModule,

        // App routes - must come after any modules with child routers
        //AppRoutingModule

    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
