<!--<div id="videoContainer" #videoContainer class=”amsterdam-acid-blue”>
    <video #videoPlayer autoplay="false" data-shaka-player id="amsterdam-acid-blue" width="100%" height="100%" poster="{{msg.resourceThumbURL}}" (click)="loadVideo()"></video>
</div>-->

<div class="video-player-wrapper">
    <vg-player (onPlayerReady)="onPlayerReady($event)">
        <vg-overlay-play></vg-overlay-play>
        <vg-buffering></vg-buffering>
        <vg-scrub-bar>
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>
        <vg-controls>
            <vg-play-pause></vg-play-pause>
            <vg-playback-button></vg-playback-button>
            <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
            <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>
            <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
            <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
            <vg-track-selector></vg-track-selector>
            <vg-mute></vg-mute>
            <vg-volume></vg-volume>
            <vg-fullscreen></vg-fullscreen>
        </vg-controls>

        <video [vgMedia]="$any(media)" #media id="singleVideo" [src]="video.src" preload="auto" crossorigin></video>

    </vg-player>
</div>
