import { ICurriculum } from "./ICurriculum";

export class Curriculum implements ICurriculum {
    name: string;
    description: string;
    entityType: string;
    id: string;
    curriculumNum: number;
    practice: string;
    priority: number;
    level: number;
    isRecommendable: string;
    programName: string;
    programId: string;
    phaseName: string;
    phaseNum: number;
    aspectGroupName: string;
    aspectGroupId: string;
    aspectGroupNum: number;
    aspectName: string;
    aspectId: string;
    scenarioTitle: string;
    scenarioId: string;
    scenAspRelType: string;
    scenAspRelLength: number;
    scenAspRelWeight: number;
}

export class ProgramCurriculum {
    programId: string;
    programName: string;
    curriculums: Curriculum[];
}
