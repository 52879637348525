import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApexOptions } from 'ng-apexcharts';
import { ILoadResult } from '../../../../models/ILoadResult';
import { ProgramMessage } from '../../../../models/ProgramMessage';
import { Dashboard } from '../../../../models/Dashboard';
import { SMService } from '../../../../services/sm.service';
import { environment } from '../../../../../environments/environment';
import { UserProfile } from '../../../../models/UserProfile';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
    baseUrl = '';
    public theURL: string;
    public thehttp: HttpClient;
    public graphLoadForm: FormGroup;
    public loadResults: ILoadResult[];
    public currentResult: string;
    public currentUser: string = "";
    public programMessage: ProgramMessage;
    //public dashboard: Dashboard;
    scenariosDone: ApexOptions;
    scenariosSeries: [number, number];
    scenariosLabels: ["Done", "Remaining"];
    @Input() dashboard: Dashboard;
    @Input() streamForUser: UserProfile; // for coaching UX
    public numCoaches: number = 0;


  constructor(
    http: HttpClient,
    private formBuilder: FormBuilder,
    private smService: SMService
  )
  {
    this.baseUrl = environment.BASE_URL;
    this.theURL = this.baseUrl;
    this.thehttp = http;
  }

    ngOnChanges(changes: SimpleChanges) {
        for (const streamForUser in changes) {
            var chng = changes[streamForUser];
            var cur = chng.currentValue;
            var prev = chng.previousValue;

            if (cur != undefined && prev != undefined) {
                console.log(`${streamForUser}: currentValue = ${cur}, previousValue = ${prev}`);

                if (cur != prev) {
                    this.UpdateDashboard(true);
                }
            }

        }
    }

  ngOnInit(): void {

      // Update the dashboard
      this.UpdateDashboard(false);

      // Subscribe to programstream message events
      this.smService.messageReceived.subscribe(result => {

          // Set the programstream to the result
          this.programMessage = result;

          // Update the Dashboard
          this.UpdateDashboard(true);

      }, error => console.error(error));
    }

    public GetPercentComplete(): number {

        var perc = 0.00;
        if (this.dashboard.programScenarioCount > 0) {
            perc = (this.dashboard.programScenarioCompletedCount / this.dashboard.programScenarioCount) * 100;
        }
        return perc;
    }

    private UpdateDashboard(forceupdate: boolean) {

        // Update the Dashboard if needed
        if (this.dashboard == null || this.dashboard == undefined || forceupdate == true) {
            this.smService.GetDashboard(this.streamForUser).subscribe(result => {
                this.dashboard = result;
                this.numCoaches = this.dashboard.numCoaches;
                this._prepareChartData();
                console.log("Dashboard reloaded.");

            }, error => console.error(error));
        } else {
            this.numCoaches = this.dashboard.numCoaches;
             // Prepare the chart data
            this._prepareChartData();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Prepare the chart data from the data
     *
     * @private
     */
    private _prepareChartData(): void {
        // Set the chart data
        this.scenariosSeries = [this.dashboard.programScenarioCompletedCount, this.dashboard.programScenarioCount - this.dashboard.programScenarioCompletedCount];

        // New vs. returning
        this.scenariosDone = {
            chart: {
                animations: {
                    speed: 400,
                    animateGradually: {
                        enabled: false
                    }
                },
                fontFamily: 'inherit',
                foreColor: 'inherit',
                height: '100%',
                type: 'donut',
                sparkline: {
                    enabled: true
                }
            },
            colors: ['#3182CE', '#63B3ED'],
            labels: this.scenariosLabels,
            plotOptions: {
                pie: {
                    customScale: 0.9,
                    expandOnClick: false,
                    donut: {
                        size: '70%'
                    }
                }
            },
            series: this.scenariosSeries,
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    filter: {
                        type: 'none'
                    }
                }
            }
        };

        console.log("scenariosDone is: " + this.scenariosDone);
    }


 }

