import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SMService } from '../../services/sm.service';
import { ILoadResult } from '../../models/ILoadResult';
import { UserProfile } from '../../models/UserProfile';
import { ProgramMessage } from '../../models/ProgramMessage';
import { Dashboard } from '../../models/Dashboard';
import { RecConfig } from '../../models/RecConfig';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

export enum SelectType {
    single,
    multiple
}

@Component({
  selector: 'app-adminprofile',
  templateUrl: './adminprofile.component.html'
})
export class AdminProfileComponent implements OnInit {
    baseUrl = '';
    public theURL: string;
    public thehttp: HttpClient;
    public loadResults: ILoadResult[];
    public currentResult: string;
    public currentUser: string = "";
    public programMessage: ProgramMessage;
    public userprofile: UserProfile;
    public recconfig: RecConfig;
    public dashboard: Dashboard;
    public profileLoaded: boolean;
    public smService: SMService;

    public users: UserProfile[];
    public coaches: UserProfile[];

    public selectedUser: UserProfile;
    public showUserDetail: boolean = false;
    public selectedCoachUser: UserProfile;
    public showCoachDetail: boolean = false;

    visibleTab: string = "";

    displayedColumns: string[] = [
        "select",
        "name",
        "id",
        "entityType"
    ];

    selectType = [
        { text: "Single", value: SelectType.single },
        { text: "Multiple", value: SelectType.multiple }
    ];

    dataSource = new MatTableDataSource<UserProfile>();
    selection = new SelectionModel<UserProfile>(true, []);
    displayType = SelectType.multiple;

    coachdataSource = new MatTableDataSource<UserProfile>();
    coachselection = new SelectionModel<UserProfile>(true, []);

    constructor(
        http: HttpClient,
        private formBuilder: FormBuilder,
        sms: SMService
        )
    {
        this.baseUrl = environment.BASE_URL;
        this.theURL = this.baseUrl;
        this.thehttp = http;
        this.smService = sms;
    }

    selectHandler(row: UserProfile) {
        if (this.displayType == SelectType.single) {
            if (!this.selection.isSelected(row)) {
                this.selection.clear();
            }
        }
        this.selection.toggle(row);
    }

    onChange(typeValue: number) {
        this.displayType = typeValue;
        this.selection.clear();
    }

    coachselectHandler(row: UserProfile) {
        if (this.displayType == SelectType.single) {
            if (!this.coachselection.isSelected(row)) {
                this.coachselection.clear();
            }
        }
        this.coachselection.toggle(row);
    }

    AssignUsersToCoaches() {
        console.log("Assigning Users to Coaches");

        // Get the selected users
        var selectedUsers = this.selection.selected;

        // Get the selected coaches
        var selectedCoaches = this.coachselection.selected;

        // Send the request to the server
        this.smService.AssignUsersToCoaches(selectedUsers, selectedCoaches).subscribe(result => {

            // TODO Update status on users to indicate assigned to coach and which coach
            // Update status on coaches to indicate # of assigned users

        }, error => console.error(error));

    }

    ShowUserDetail(user) {
        this.showUserDetail = true;
        this.selectedUser = user;

        // Get list of assigned coaches for the user detail
        this.smService.GetCoachesForUser(user).subscribe(result => {

            // Push the received coaches into the user's coach array
            this.selectedUser.coaches = result.coaches;

        }, error => console.error(error));

    }

    ShowCoachDetail(coach) {
        this.showCoachDetail = true;
        this.selectedCoachUser = coach;

        // Get list of assigned users for the coach detail
        this.smService.GetUsersForCoach(coach).subscribe(result => {

            this.selectedCoachUser.users = result.users;

            //
        }, error => console.error(error));

    }


    ngOnInit(): void {

        // Get the Admin Profile
        this.smService.GetAdminProfile().subscribe(result => {
            //this.userprofile = result.userProfile;
            //this.recconfig = result.userProfile.recConfig;
            //this.dashboard = result.dashboard;
            this.users = result.users;
            this.coaches = result.coaches;
            this.profileLoaded = true;

            this.dataSource = new MatTableDataSource<UserProfile>(this.users);
            this.coachdataSource = new MatTableDataSource<UserProfile>(this.coaches);

            this.smService.CurrentView = "Scenarios";

        }, error => console.error(error));
    }


    public changedTab($event) {
        let clickedIndex = $event.index;

        switch (clickedIndex) {
            case 0:
                this.visibleTab = "Users";
                break;
            case 1:
                this.visibleTab = "Coaches";
                break;
            case 2:
                this.visibleTab = "Aspects";
                break;
            case 3:
                this.visibleTab = "Aspect Aspect Group Map";
                break;
            case 4:
                this.visibleTab = "Scenarios";
                break;
            case 5:
                this.visibleTab = "Scenario Aspect Map";
                break;
        }

        this.smService.CurrentView = this.visibleTab;

        console.log("Current Tab is: " + this.visibleTab);
    }


}

