import { Input, OnInit } from '@angular/core';
import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ProgramMessage } from '../../../../../models/ProgramMessage';
import { ScenarioRecommendation } from '../../../../../models/ScenarioRecommendation';
import { SMService } from '../../../../../services/sm.service';


@Component({
    selector: 'app-videoviewer-player',
    templateUrl: './videoviewer.component.html',
    styleUrls: ['./videoviewer.component.css', './controls.css', 'amsterdam-acid-blue.css'],
})


export class VideoViewerComponent implements OnInit {

    public scenarioRec: ScenarioRecommendation;
    @Input() type: string;
    public source: string = "";
    public thumbnail: string = "";
    public video: any = {
        src: "",
        type: 'video/mp4'
    };

    public hasScenario: boolean = false;

    constructor(
        private smService: SMService,
    ) { }

    ngOnInit(): void {

        // TODO - refactor to use a single observable for the video source and thumbnail
        var y = this.smService.VideoSource.subscribe(vidurl => {
            this.source = vidurl;

            if (this.source != null && this.source != undefined) {
                this.hasScenario = true;

                var z = this.smService.VideoSourceThumb.subscribe(vidthumburl => {
                    this.thumbnail = vidthumburl;

                    if (this.source != null && this.source != undefined) {
                        this.hasScenario = true;

                        this.video = {
                            src: this.source,
                            poster: this.thumbnail,
                            type: 'video/mp4'
                        }

                        //console.log("Video Source is: " + this.source);
                    }
                }
                );
            }
        }
        );


        // Load the selected scenario recommendation
        //this.LoadCurrentScenario();

        //this.source = this.videoSource;

    }

    LoadCurrentScenario() {
        this.hasScenario = false;
        this.scenarioRec = new ScenarioRecommendation();
        this.scenarioRec.title = "No Scenario Loaded";

        var x = this.smService.CurrentScenarioRec.subscribe(scenarioRec => {
            this.scenarioRec = scenarioRec;
            this.hasScenario = true;

            if (this.type == 'scenario') {
                this.source = this.scenarioRec.resourceURL;
            } else if (this.type == 'response') {
                this.source = this.scenarioRec.responseURL;

                var y = this.smService.CurrentResponse.subscribe(resp => {
                    this.source = resp.responseFileName;
                }
                );
                //y.unsubscribe();

            } else if (this.type != 'scenario' && this.type != 'response' && this.type != '') {
                // Hack to show previews of uploaded response videos
                this.source = this.type;
            }

            this.video = {
                src: this.source,
                type: 'video/mp4'
            }

            console.log("Video Source is: " + this.source);

        }
        );
        //x.unsubscribe();
    }

    onPlayerReady(source) {

        //console.log("onPlayerReady Called");

        //console.log("Video src set to: " + this.video.src);

        // TODO Load the player
        /*this.video = {
            src: this.msg.resourceURL,
            type: 'video/mp4'
        }*/
    }

}

        
