
<div class="profilePanel mx-auto w-full h-full">

    <div class="">
        <div class="flex flex-col bg-slate-50 pb-8 items-center w-full">
            <ng-container *ngIf="showTrialStepper">
                <app-trialstepper class="w-full"></app-trialstepper>
            </ng-container>

            <div class="justify-center p-8 programListContainer">
                <div class="SectionHeaderLeft -mt-2 mb-2">Welcome {{getCurrentUserName()}}</div>

                <!-- Summary Dashboard -->
                <div class="flex-auto grid grid-cols-8 gap-4">

                    <div class="flex flex-col col-span-4 p-4 bg-card shadow-sm rounded-md overflow-hidden">
                        <div class="flex items-start justify-between">
                            <div class="text-2xl font-bold tracking-tight leading-6 truncate mb-2">Progress</div>
                        </div>
                        <div class="my-2 flex flex-row items-center bg-card rounded-md bg-green-50 p-2" *ngIf="showProgramIntro">
                            <img src="../../../../../../assets/images/avatars/cogsolo__mobile_logo_1.png" class="cogsologuy mr-2" rounded-full />
                            <div class="flex min-w-0 flex-col">
                                <div class="font-semibold">Progress</div>
                                <div class="">{{programIntro}}</div>
                            </div>
                            <div><button mat-flat-button color="primary" class="ml-5" (click)="AcceptProgram()">Ok</button></div>
                        </div>

                        <div class="flex-auto grid grid-cols-6 gap-1 mx-2">
                            <div class="col-span-2 sm:col-span-2 flex flex-col items-center justify-center py-4 px-1 rounded-2xl bg-gray-100 text-secondary dark:bg-white dark:bg-opacity-5">
                                <div class="text-4xl font-bold text-gray-600 dark:text-gray-500">{{programsummary.programName}}</div>
                                <div class="text-base mt-1 font-medium text-center text-gray-600 dark:text-gray-500">Program</div>
                            </div>

                            <div class="col-span-1 sm:col-span-1 flex flex-col items-center justify-center py-1 px-1 rounded-2xl bg-gray-100 text-secondary dark:bg-white dark:bg-opacity-5">
                                <div class="text-4xl font-bold text-gray-600 dark:text-gray-500">{{getProgramLevel()}}</div>
                                <div class="text-base mt-1 font-medium text-center text-gray-600 dark:text-gray-500">Level</div>
                            </div>

                            <div class="col-span-3 sm:col-span-3 flex flex-col items-center justify-center py-4 px-1 rounded-2xl bg-gray-100 text-secondary dark:bg-white dark:bg-opacity-5">
                                <ng-template [ngTemplateOutlet]="ProgressChart" *ngIf="showProgressChart"></ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col col-span-4 p-4 bg-card shadow-sm rounded-md overflow-hidden" *ngIf="showPointsIntro || showRecButtonIntro || showCurriculumIntro || !isOnboarding">
                        <div class="flex items-start justify-between">
                            <div class="text-2xl font-bold tracking-tight leading-6 truncate mb-2">Scoring</div>
                        </div>
                        <div class="my-2 flex flex-row items-center bg-card rounded-md bg-green-50 p-2" *ngIf="showPointsIntro">
                            <img src="../../../../../../assets/images/avatars/cogsolo__mobile_logo_1.png" class="cogsologuy mr-2" rounded-full />
                            <div class="flex min-w-0 flex-col">
                                <div class="font-semibold">Scoring</div>
                                <div class="">{{pointsIntro}}</div>
                            </div>
                            <div><button mat-flat-button color="primary" class="ml-5" (click)="AcceptPoints()">Ok</button></div>
                        </div>
                        <div class="grid grid-cols-3 gap-4">
                            <div class="col-start-1 col-end-2 flex flex-col items-center justify-center py-4 px-1 rounded-2xl bg-gray-100 text-secondary dark:bg-white dark:bg-opacity-5">
                                <div class="text-4xl sm:text-4xl font-bold text-teal-500">{{getPoints()}}</div>
                                <div class="text-base font-medium text-teal-600 dark:text-teal-500">Points</div>
                            </div>

                            <div class="col-end-4 col-span-2 flex flex-col items-center justify-center py-1 px-1 text-secondary dark:bg-white dark:bg-opacity-5">

                                <mat-table [dataSource]="pointsdataSource" class="mat-elevation-z8">

                                    <ng-container matColumnDef="displayName">
                                        <mat-header-cell *matHeaderCellDef> Top Point Sources </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="text-md font-medium dashboardCell"> {{element.description}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="points">
                                        <mat-header-cell *matHeaderCellDef> Pts </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="text-md font-medium dashboardCell"> {{element.points}} </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="pointsColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: pointsColumns;">
                                    </mat-row>

                                </mat-table>



                            </div>

                        </div>
                    </div>



                </div>

                <!-- Scenarios Table -->
                <div class="flex flex-auto grid grid-cols-6 scenariolist w-full mt-4" *ngIf="showRecButtonIntro || showCurriculumIntro || !isOnboarding">

                    <div class="flex flex-col col-span-6 p-4 bg-card shadow-sm rounded-md">
                        <div class="flex items-start justify-between">
                            <div class="text-2xl font-bold tracking-tight leading-6 truncate mb-2">My Personal Program</div>
                        </div>

                        <!-- Toolbar -->
                        <div class="flex flex-row items-center mt-2 mb-2">
                            <div class="my-2 flex flex-row items-center bg-card rounded-md bg-green-50 p-2 w-full" *ngIf="showRecButtonIntro">
                                <img src="../../../../../../assets/images/avatars/cogsolo__mobile_logo_1.png" class="cogsologuy mr-2" rounded-full />
                                <div class="flex min-w-0 flex-col">
                                    <div class="font-semibold">Get Next Best Scenario</div>
                                    <div class="">{{recButtonIntro}}</div>
                                </div>
                                <div>
                                    <button mat-flat-button color="primary" class="ml-5" [disabled]="disableRecButton" (click)=" GetNewRecommendation()">
                                        <mat-icon>recommend</mat-icon><span class="ml-2">Get Next Best Scenario</span>
                                    </button>
                                </div>
                            </div>

                            <div class="my-2 flex flex-row items-center p-2" *ngIf="!isOnboarding">
                                <div>
                                    <button mat-flat-button color="primary" class="mr-5" (click)="GetNewRecommendation()">
                                        <mat-icon>recommend</mat-icon><span class="ml-2">Get Next Best Scenario</span>
                                    </button>
                                </div>
                                <div>
                                    <!-- Alert -->
                                    <fuse-alert class=""
                                                *ngIf="showAlert"
                                                [appearance]="'outline'"
                                                [showIcon]="true"
                                                [type]="alert.type">
                                        <span fuseAlertTitle>Alert</span>
                                        <img src="../../../../../../assets/images/avatars/cogsolo__mobile_logo_1.png" class="cogsologuy mr-2" fuseAlertIcon rounded-full />
                                        {{alert.message}}
                                    </fuse-alert>
                                </div>
                            </div>
                        </div>


                        <div class="my-2 flex flex-row items-center bg-card rounded-md bg-green-50 p-2" *ngIf="showCurriculumIntro">
                            <img src="../../../../../../assets/images/avatars/cogsolo__mobile_logo_1.png" class="cogsologuy mr-2" rounded-full />
                            <div class="flex min-w-0 flex-col">
                                <div class="font-semibold">My Personal Program</div>
                                <div class="">{{curriculumIntro}}</div>
                            </div>
                        </div>
                        <div class="">
                            <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                                <ng-container matColumnDef="roundNum">
                                    <mat-header-cell *matHeaderCellDef class="justify-center"> # </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="justify-center"> <span class="text-2xl font-bold">{{element.roundNum}} </span></mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="title">
                                    <mat-header-cell *matHeaderCellDef class="justify-left"> Scenario </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="justify-left">
                                        <span class="text-xl font-bold">{{element.title}} </span>
                                        <!--<mat-expansion-panel class="reasonExpansionPanel w-full">
                                <mat-expansion-panel-header>
                                    <mat-panel-title> {{element.title}} </mat-panel-title>
                                </mat-expansion-panel-header>

                                <div class="flex flex-col items-start">
                                    <div class="flex flex-row">
                                        <div class="text-md font-semibold text-gray-600 dark:text-gray-500 px-1">Why this Scenario:</div>
                                    </div>
                                    <div class="flex flex-row" *ngFor="let reason of element.reasons">
                                        <div class="py-0.5 px-2 text-sm">{{reason.simpleText}}</div>
                                    </div>
                                </div>

                            </mat-expansion-panel>-->

                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="relatedAspects">
                                    <mat-header-cell *matHeaderCellDef class=""> Related Aspects </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="flex flex-col relatedAspects items-start justify-center">
                                        <div *ngFor="let item of getRelatedAspects(element)">
                                            <div class="text-md">{{item.name}} </div>
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="difficulty">
                                    <mat-header-cell *matHeaderCellDef class="justify-center"> Difficulty </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="justify-center">
                                        <span class="inline-flex font-bold text-md px-2 py-0.5 rounded-full tracking-wide uppercase"
                                              [ngClass]="{
                                                'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': element.difficulty === 'Advanced',
                                                'bg-orange-200 text-orange-800 dark:bg-orange-600 dark:text-orange-50': element.difficulty === 'MediumHigh',
                                                'bg-gray-200 text-black-800 dark:bg-gray-600 dark:text-black-50': element.difficulty === 'Intermediate',
                                                'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': element.difficulty === 'MediumLow',
                                                'bg-blue-200 text-blue-800 dark:bg-blue-600 dark:text-blue-50': element.difficulty === 'Basic'}">
                                            <span class="leading-relaxed whitespace-nowrap">{{element.difficulty}}</span>
                                        </span>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="resourceURL">
                                    <mat-header-cell *matHeaderCellDef class="justify-center"> Scenario Video </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="justify-center">
                                        <div class="flex flex-col items-start">
                                            <img class="scenarioThumb" [src]="getScenarioThumbUrl(element)" />
                                            <div class="mt-1 mb-2 text-sm text-center">{{element.timestamp | date : "short" }}</div>
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="responseThumbURL">
                                    <mat-header-cell *matHeaderCellDef class="justify-center"> Last Response </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="justify-center">
                                        <div class="flex flex-col items-start">
                                            <img class="scenarioThumb" [src]=getLastResponseThumbUrl(element) />
                                            <div class="mt-1 mb-2 text-sm createdDateColumn">
                                                <span *ngIf="!element.responseDate==''">{{getLastResponseDate(element) | date : "short" }}</span>
                                                <span *ngIf="element.responseDate=='' || element.responseDate=='undefined'"> N/A </span>
                                            </div>
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="feedbackStatus">
                                    <mat-header-cell *matHeaderCellDef class="justify-center"> Feedback </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="justify-center">

                                        <div class="inline-flex items-center font-bold text-md px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                                             [ngClass]="{'bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-50': getFeedbackStatus(element) === 'No Response',
                                                'bg-green-200 text-green-600 dark:bg-green-600 dark:text-green-50': getFeedbackStatus(element) === 'Posted'}">
                                            <span class="leading-relaxed whitespace-nowrap">{{getFeedbackStatus(element)}}</span>
                                        </div>
                                        <div class="inline-flex items-center font-bold text-lg px-2.5 py-0.5 tracking-wide text-green-600 dark:text-green-50">
                                            <span class="text-lg leading-relaxed whitespace-nowrap">{{getItemPoints(element)}}</span>
                                        </div>


                                    </mat-cell>
                                </ng-container>

                                <!-- Detail Column -->
                                <ng-container matColumnDef="details">
                                    <mat-header-cell *matHeaderCellDef class="justify-center"> Details </mat-header-cell>
                                    <mat-cell *matCellDef="let element" class="justify-center">
                                        <button class="px-3"
                                                mat-flat-button
                                                [disabled]=""
                                                [color]="'primary'" (click)="ShowItemDetail(element)">
                                            <span class="mr-2">Details</span><mat-icon>arrow_forward</mat-icon>
                                        </button>

                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;">
                                </mat-row>
                            </mat-table>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>




<ng-template #ProgressChart>


    <div class="flex flex-row flex-auto items-center justify-center h-30">
        <apx-chart class="flex flex-auto -mx-10 h-24"
                   [chart]="chartNewVsReturning.chart"
                   [colors]="chartNewVsReturning.colors"
                   [labels]="chartNewVsReturning.labels"
                   [plotOptions]="chartNewVsReturning.plotOptions"
                   [series]="chartNewVsReturning.series"
                   [states]="chartNewVsReturning.states"
                   [tooltip]="chartNewVsReturning.tooltip">
        </apx-chart>
        <div class="flex flex-col justify-center h-full mr-2">

            <ng-container *ngFor="let dataset of progressChartData.series; let i = index">
                <div class="grid grid-cols-4 py-2">
                    <div class="flex items-center col-span-2">
                        <div class="flex-0 w-2 h-2 rounded-full"
                             [style.backgroundColor]="chartNewVsReturning.colors[i]"></div>
                        <div class="ml-3 text-base font-medium">{{progressChartData.labels[i]}}</div>
                    </div>
                    <div class="text-base font-medium text-right">{{programsummary.programScenarioCount * dataset / 100 | number:'1.0-0'}}</div>
                    <div class="text-base text-right text-secondary">{{dataset}}%</div>
                </div>
            </ng-container>

            <div class="grid grid-cols-4">
                <div class="flex items-center col-span-4">
                    <div class="text-base mt-1 font-medium font-semibold text-center text-gray-600 dark:text-gray-500">Scenarios</div>
                </div>
            </div>

        </div>

    </div>



</ng-template>
