import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared/shared.module';
import { DashboardComponent } from './dashboard.component';



/*const exampleRoutes: Route[] = [
    {
        path     : '',
        component: DashboardComponent
    }
];*/

@NgModule({
    declarations: [
        DashboardComponent
    ],
    imports     : [
        //RouterModule.forChild(exampleRoutes),
        SharedModule
    ],
    exports: [
        DashboardComponent
    ]
})
export class DashboardModule
{
}
