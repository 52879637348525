import { IProgramMessage } from './IProgramMessage';
import { IReason } from './IReason';

export class ProgramMessage implements IProgramMessage {
    id: string;
    name: string;

    partitionKey: string;
    rowKey: string;
    userId: string;
    userName: string;
    programId: string;
    authorId: string;
    authorName: string;
    messageType: string;
    messageDate: Date;
    messageNumber: number;
    entityType: string;
    messageText: string;
    status: string;
    aspectId: string;
    scenarioId: string;
    aspectName: string;
    scenarioName: string;
    reasons: IReason[];
    responseFileName: string;
    responseFileThumb: string;
    resourceURL: string;
    resourceThumbURL: string;
    streamForUserName: string;
    imageURL: string; // Url of the user's profile image
    feedbackStatus: string; // Status of the feedback for the user response
    scenarioRecommendationId: string; // ID of the scenario recommendation entity associated with a scenario
    programName: string;
    roundId: string;
    roundNum: number;
    responseType: string; // Response or Practice denoting a practice response
    responseId: string; // ID of the user response entity
    engagementType: string; // Engagement type of the user response
    isNew: boolean; // Flag to denote if the message is new
    constructor() {
        this.id = "";
        this.name = ""; 
    }

}
