import { NgModule } from '@angular/core';
import { RecConfigComponent } from 'app/modules/userprofile/recconfig/recconfig.component';
import { SharedModule } from '../../../shared/shared.module';
import { RecConfigRoutingModule } from './recconfig.routing';

@NgModule({
    declarations: [
        RecConfigComponent
    ],
    imports     : [
        SharedModule,
        RecConfigRoutingModule
    ],
    exports: [
        RecConfigComponent
    ]
})
export class RecConfigModule
{
}
