import { Rubric } from "./Rubric";

export class AdminRubricDto {
    scenarioId: string;
    rubrics: Rubric[];
}

export class RubricDto {
    rubric: Rubric;
}
