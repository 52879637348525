
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <!-- Logo -->
            <!--<div class="w-12">
                <img src="assets/images/logo/logo.svg">
            </div>-->

            <!-- Title -->
            <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">Forgot password?</div>
            <div class="mt-0.5 font-medium">Fill the form to reset your password</div>

            <!-- Alert -->
            <fuse-alert
                class="mt-8"
                *ngIf="showAlert"
                [appearance]="'outline'"
                [showIcon]="false"
                [type]="alert.type"
                [@shake]="alert.type === 'error'">
                {{alert.message}}
            </fuse-alert>

            <!-- Forgot password form -->
            <form class="mt-8"
                  [formGroup]="forgotPasswordForm"
                  #forgotPasswordNgForm="ngForm">

                <!-- UserName field -->
                <mat-form-field class="w-full">
                    <mat-label>User Name</mat-label>
                    <input id="username"
                           matInput
                           [formControlName]="'username'">
                    <mat-error *ngIf="forgotPasswordForm.get('username').hasError('required')">
                        Username is required
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('username').hasError('email')">
                        Please enter the username you used to sign up
                    </mat-error>
                </mat-form-field>

                <!-- Email field -->
                <mat-form-field class="w-full">
                    <mat-label>Email address</mat-label>
                    <input id="email"
                           matInput
                           [formControlName]="'email'">
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
                        Email address is required
                    </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <!-- Submit button -->
                <button class="fuse-mat-button-large w-full mt-3"
                        mat-flat-button
                        [color]="'primary'"
                        [disabled]="forgotPasswordForm.disabled"
                        (click)="sendResetLink()">
                    <span *ngIf="!forgotPasswordForm.disabled">
                        Send Reset Link
                    </span>
                    <mat-progress-spinner *ngIf="forgotPasswordForm.disabled"
                                          [diameter]="24"
                                          [mode]="'indeterminate'"></mat-progress-spinner>
                </button>

                <!-- Form footer -->
                <div class="mt-8 text-md font-medium text-secondary">

                    <a mat-button (click)="cancelForgotPassword()">Cancel</a>

                </div>

            </form>
        </div>

