import { IEntityBase } from './IEntityBase';
import { IUserNotesItem } from './IUserNotesItem';

export class UserNotesItem implements IUserNotesItem {
    description: string;
    entityType: string;
    id: string;
    name: string;

    userName: string;
    userId: string;
    scenarioId: string;
    number: number;
    noteType: string;
    userComment: string;
    createdDate: Date;
    rowKey: string;
}


export class UserNotesDto {
    status: string;
    token: string;
    scenarioId: string;

    userNotesItems: UserNotesItem[];
}
