import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class VideoProcessingService {

    constructor(
        @Inject(DOCUMENT) private document: Document
    ) { }

    public promptForVideo(): Promise<File> {
        return new Promise<File>((resolve, reject) => {
            // make file input element in memory
            const fileInput: HTMLInputElement = this.document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'video/*';
            fileInput.setAttribute('capture', 'camera');
            // fileInput['capture'] = 'camera';
            fileInput.addEventListener('error', event => {
                reject(event.error);
            });
            fileInput.addEventListener('change', event => {
                resolve(fileInput.files[0]);
            });
            // prompt for video file
            fileInput.click();
        });
    }

    public generateThumbnail(videoFile: Blob, videoURL: string, createURL: boolean): Promise<string> {
        const video: HTMLVideoElement = this.document.createElement('video');
        const canvas: HTMLCanvasElement = this.document.createElement('canvas');
        const context: CanvasRenderingContext2D = canvas.getContext('2d');
        return new Promise<string>((resolve, reject) => {
            canvas.addEventListener('error', reject);
            video.addEventListener('error', reject);
            video.addEventListener('canplay', event => {
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                const dataUrl = canvas.toDataURL();
                //var convstr = dataUrl.substring(dataUrl.lastIndexOf(',') + 1)
                // Convert to Base64 string
                const base64url = this.getBase64StringFromDataURL(dataUrl);
                resolve(base64url);
                //resolve(dataUrl);
            });
            if (createURL) {
                if (videoFile.type) {
                    video.setAttribute('type', videoFile.type);
                }
            } else {
                video.setAttribute('type', "video/mp4");
            }

            video.preload = 'auto';
            if (createURL) {
                video.src = window.URL.createObjectURL(videoFile);
            } else {
                video.src = videoURL;
            }
            video.load();
        });
    }

    getBase64StringFromDataURL(dataURL: string) {
        return dataURL.replace('data:', '').replace(/^.+,/, '');
    }

}
