import { NgModule } from '@angular/core';
import { AdminScenarioMapComponent } from 'app/modules/adminprofile/adminscenariomap/adminscenariomap.component';
import { SharedModule } from '../../../shared/shared.module';



@NgModule({
    declarations: [
        AdminScenarioMapComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        AdminScenarioMapComponent
    ]
})
export class AdminScenarioMapModule
{
}
