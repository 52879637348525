import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { UserProfile } from '../../../../models/UserProfile';
import { SMService } from '../../../../services/sm.service';

@Component({
    selector     : 'modern-layout',
    templateUrl  : './modern.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ModernLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    @Input() userType: string;

    public hasProfileImage: boolean = false;
    public userprofile: UserProfile;

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private smService: SMService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------



    /**
     * On init
     */
    ngOnInit(): void
    {
        this.hasProfileImage = true;

        // Subscribe to navigation data
        /*this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });
            */

        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        // Load User Profile for Navigation
        this.userprofile = new UserProfile();
        if (this.smService.profiledetail == null || this.smService.profiledetail == undefined) {
            this.LoadUserProfile();
        } else {
            this.userprofile = this.smService.profiledetail.getValue();
        }
    }

    private LoadUserProfile() {
        // Update the UserProfile
        this.smService.GetUserProfile().subscribe(result => {
            this.userprofile = result.userProfile;
            this.smService.profiledetail.next(this.userprofile);

            //            if (this.userprofile.userStatus != "Onboarding") {
            this.hasProfileImage = true;
            //            } else {
            //                this.userprofile.imageURL != null && this.userprofile.imageURL != "" ? this.hasProfileImage = true : this.hasProfileImage = false;
            //            }

        }, error => console.error(error));
    }


    getProfileImage(): string {
        if (this.hasProfileImage) {
            var profile = this.smService.profiledetail.getValue();
            return profile.imageURL;
        }
        else {
            return "assets/images/avatars/Avatar_placeholder.png";
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    showUserProfile() {
        this.router.navigate(['userprofile', 'programsummarylist'])
            .then(nav => {
                console.log(nav); // true if navigation is successful
            }, err => {
                console.log(err) // when there's an error
            });
    }

}
