<!-- Main -->
<div class="flex-auto border-t -mt-px pt-4 sm:pt-6">
    <div class="w-full mx-auto">

        <!-- Tabs -->
        <mat-tab-group (selectedTabChange)="changedTab($event)"
                       class="sm:px-2"
                       mat-stretch-tabs="false"
                       [animationDuration]="'0'">

            <!-- Home -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="text-2xl rounded-t-xl border-t-1 border-x-1">
                        Users
                    </div>
                </ng-template>
                <ng-template matTabContent>
                    <ng-template [ngTemplateOutlet]="UserList"></ng-template>
                </ng-template>
            </mat-tab>

            <!-- Scenarios -->
            <!--<mat-tab label="Scenarios">
                <ng-template matTabContent>
                    <ng-template [ngTemplateOutlet]="ScenariosList"></ng-template>
                </ng-template>
            </mat-tab>-->

        </mat-tab-group>

    </div>
</div>

<ng-template #ScenariosList>
    <div class="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate mb-6">Scenarios</div>
    <!-- Controls -->
    <div class="flex items-center justify-left mt-8">
        <button class="px-6 ml-3"
                mat-flat-button
                [color]="'primary'" (click)="LoadScenariosForUser()">
            Load Scenarios for User
        </button>
    </div>
</ng-template>


<ng-template #UserList>
    <div *ngIf="userprofile" class="flex-none">
        <div class="relative flex flex-auto w-full bg-card dark:bg-transparent bg-slate-50">

            <!-- Chats list -->
            <ng-container *ngIf="users && users.length > 0; else noUsers">
                <div class="relative flex flex-auto flex-col w-full min-w-0 lg:min-w-100 lg:max-w-100 bg-card dark:bg-transparent">

                    <!-- Header -->
                    <div class="flex flex-col flex-0 py-4 px-8 border-b bg-gray-50 dark:bg-transparent">
                        <div class="flex items-center">
                            <div class="flex items-center mr-1 cursor-pointer">
                                <div class="text-lg ml-4 font-semibold truncate">Select a User</div>
                            </div>

                        </div>
                    </div>

                    <!-- Chats -->
                    <div class="flex-auto overflow-y-auto">
                        <ng-container *ngIf="users.length > 0; else noUsers">
                            <ng-container *ngFor="let user of users; trackBy: trackByFn">
                                <div class="z-20 flex items-center py-5 px-8 cursor-pointer border-b no-underline"
                                     [ngClass]="{'hover:bg-gray-100 dark:hover:bg-hover': !selectedUser || selectedUser.id !== user.id,
                                        'bg-primary-50 dark:bg-hover': selectedUser && selectedUser.id === user.id}"
                                     (click)="SelectUser(user)">
                                    <div class="min-w-0 ml-4 no-underline">
                                        <div class="text-xl leading-5 truncate">{{user.name}}</div>
                                    </div>
                                    <!-- TODO  will need to set this value in the CoachUsers table so as not to retrieve it for each profile individually -->
                                    <div class="flex flex-col items-end self-start ml-auto pl-2 no-underline">
                                        <div class="text-lg leading-5 font-semibold text-red-600 dark:text-red-500" *ngIf="user.requestedHumanReview">Review Requested</div>
                                    </div>

                                </div>
                            </ng-container>
                        </ng-container>
                    </div>

                </div>

            </ng-container>

            <!-- No chats template -->
            <ng-template #noUsers>
                <div class="flex flex-auto flex-col items-center justify-center h-full">
                    <mat-icon class="icon-size-24"
                              [svgIcon]="'heroicons_outline:chat'"></mat-icon>
                    <div class="mt-4 text-2xl font-semibold tracking-tight text-secondary">No chats</div>
                </div>
            </ng-template>

            <!-- Conversation -->
            <ng-container *ngIf="users && users.length > 0">
                <div class="flex-auto border-l"
                     [ngClass]="{'z-20 absolute inset-0 lg:static lg:inset-auto flex': selectedUser && selectedUser.id,
                        'hidden lg:flex': !selectedUser || !selectedUser.id}">

                    <div *ngIf="selectedUser" class="flex-auto bg-gray-50 p-8">
                            <div class="w-1/3 p-2 overflow-hidden rounded-t-xl border-t-1 border-x-1 bg-slate-50 mt-8 ml-8">
                                <div class="text-base text-center leading-snug font-semibold truncate">Selected User: {{selectedUser.name}}</div>
                            </div>
                        <app-programstream [coachMode]="coachMode" [streamForUser]="selectedUser"></app-programstream>
                    </div>



                </div>
            </ng-container>


        </div>
    </div>

</ng-template>

