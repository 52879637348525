import { NgModule } from '@angular/core';
import { AdminScenariosComponent } from 'app/modules/adminprofile/adminscenarios/adminscenarios.component';
import { SharedModule } from '../../../shared/shared.module';
import { VideoUploaderModule } from '../../userprofile/programstream/videouploader/videouploader.module';
import { VideoViewerModule } from '../../userprofile/programsummary/programscenariodetail/videoviewer/videoviewer.module';
import { VideoProcessingService } from '../../userprofile/programsummary/programscenariodetail/viewresponse/video-processing-service';



@NgModule({
    declarations: [
        AdminScenariosComponent
    ],
    imports: [
        SharedModule,
        VideoViewerModule,
        VideoUploaderModule,
    ],
    providers: [
        VideoProcessingService
    ],
    exports: [
        AdminScenariosComponent
    ]
})
export class AdminScenariosModule
{
}
