import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, finalize, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { AdminProfileDto, UserProfile, SubmitProfileDto, UpdateProfileDto, SimpleProfileUpdateDto, UserProfileDto } from '../models/UserProfile';
import { LoadResult } from '../models/LoadResult';
import { Dashboard } from '../models/Dashboard';
import { ProgramStream } from '../models/ProgramStream';
import { ProgramMessage } from '../models/ProgramMessage';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ConfigDto, RecConfig, RecConfigDto } from '../models/RecConfig';
import { Scenario } from '../models/Scenario';
import { Aspect } from '../models/Aspect';
import { Edge } from '../models/Edge';
import { BaseAspectsGraph } from '../models/BaseAspectsGraph';
import { ProgramSummary } from '../models/ProgramSummary';
import { ScenarioRecommendation } from '../models/ScenarioRecommendation';
import { FeedbackOutput } from '../models/FeedbackOutput';
import { UserResponse } from '../models/UserResponse';
import { FeedbackMaster } from '../models/FeedbackMaster';
import { FeedbackStatus, FeedbackStatusArray } from '../models/FeedbackStatus';
import { Rubric } from '../models/Rubric';
import { AdminRubricDto, RubricDto } from '../models/AdminRubricDto';
import { ScenarioTrainerDto } from '../models/ScenarioTrainerDto';
import { ScenarioGuidanceDto, ScenarioGuidanceItem } from '../models/ScenarioGuidanceDto';
import { Curriculum, ProgramCurriculum } from '../models/Curriculum';
import { AspectPreferencesDto } from '../models/AspectPreferencesDto';
import { user } from '../mock-api/common/user/data';
import { UserNotesDto, UserNotesItem } from '../models/UserNotesItem';
import { Rating } from '../models/Rating';


@Injectable({
  providedIn: 'root'
})

export class SMService implements OnInit {

    // FIELDS ------------------------------------------------------------------------------------------
    // System URL
    baseURL: string = environment.API_URL;

    // Source for Registration
    public registerSource = new ReplaySubject<boolean>(1);
    registerMode$ = this.registerSource.asObservable();

    // Connection for Messaging
    private userConnectionID: string;
    public programstream = new ProgramStream();

    // Connection for real time communications
    public connection: HubConnection;
    messageReceived = new EventEmitter<ProgramMessage>();
    feedbackMessageReceived = new EventEmitter<FeedbackStatus>();
    public thenable: Promise<void>;

    public CurrentView: string;
    public recconfig: RecConfig;
    public dashboard: Dashboard;
    public feedbackOutputs: FeedbackOutput[] = [];
    public programName: string;
    public programSummary: ProgramSummary;

    // Observables
    public uploadProgress = new Subject<number>();
    public uploadSub: Subscription;
    public feedbackCompleted = new Subject<boolean>();
    public CurrentScenarioRec = new BehaviorSubject<ScenarioRecommendation>(new ScenarioRecommendation());
    public CurrentScenario = new BehaviorSubject<Scenario>(new Scenario());
    public CurrentResponse = new BehaviorSubject<UserResponse>(new UserResponse());
    public VideoSource = new BehaviorSubject<string>("");
    public VideoSourceThumb = new BehaviorSubject<string>("");
    public VideoFile = new BehaviorSubject<File>(null);
    public NewResponse = new BehaviorSubject<UserResponse>(new UserResponse());
    public CurrentFeedbackStatusArray = new BehaviorSubject<FeedbackStatusArray>(new FeedbackStatusArray());
    public menuAction = new BehaviorSubject<string>("");
    public currentProfile = new BehaviorSubject<UserProfile>(new UserProfile());

    public showScenarioMenu = new BehaviorSubject<boolean>(true);
    public profiledetail = new BehaviorSubject<UserProfile>(new UserProfile());
    public onboardingStep = new BehaviorSubject<number>(1); // to track onboarding step number
    public isOnboarding = new BehaviorSubject<boolean>(false); // to track if user is in onboarding mode
    public navigationCreated = new BehaviorSubject<boolean>(false); // to track if navigation has been created
    public isResponseScreen = new BehaviorSubject<boolean>(false); // to track if user is in response screen

    public isTrialRegistration = new BehaviorSubject<boolean>(false);
    public userType = new BehaviorSubject<string>(""); // to track trialUser or regular user
    public trialUserDto = new BehaviorSubject<UserDto>(new UserDto()); // to track trialUser info
    public isRegistered = new BehaviorSubject<boolean>(false); // to track if user is registered
    public isSignedIn = new BehaviorSubject<boolean>(false); // to track if user is signed in

    public joinNow = new BehaviorSubject<string>(""); // to trac start of a trial
    public showNotesPanel = new BehaviorSubject<boolean>(false);

    // GUIDANCE COMPONENT FIELDS ------------------------------------------------------------------------------------------
    // Tracked here because the same component is used across view scenario and view response components
    public showGuidanceBar = new BehaviorSubject<boolean>(false);
    private pstartingPoints = new BehaviorSubject<ScenarioGuidanceItem[]>([]);
    public startingPoints = this.pstartingPoints.asObservable();

    public ptalkingPoints = new BehaviorSubject<ScenarioGuidanceItem[]>([]);
    public talkingPoints = this.ptalkingPoints.asObservable();

    public showGuidanceForm: boolean = false; // Whether to show the guidance form
    public showStartingPoints: boolean = false; // Whether to show the starting points
    public showTalkingPoints: boolean = false; // Whether to show the talking points
    public showTalkTrackButton: boolean = false; // Whether to show the talk track button
    public showTryButton: boolean = false; // Whether to show the try button
    public expandGuidanceForm: boolean = false; // Whether to expand the guidance form
    public expandStartingPoints: boolean = false; // Whether to expand the starting points
    public showStartingPointsButton: boolean = false; // Whether to show the starting points button

    public showMainMenu = new BehaviorSubject<boolean>(false);

    // CONSTRUCTOR ------------------------------------------------------------------------------------------
    constructor(
        private http: HttpClient
    ) {

        // Connect to real time comms hub
        var token = this.GetCurrentUserToken();
        this.connection = new HubConnectionBuilder()
            .configureLogging(LogLevel.Debug)
            .withUrl(this.baseURL + 'chatHub', {
                accessTokenFactory: () => token,
                withCredentials: false,
                //skipNegotiation: true,
                //transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .build();


        /*async function start() {
            try {
                await this.connection.start().catch(err => {
                    console.error("The Start Connection ERROR IS: " + err.toString());
                });
                console.log("SignalR Connected.");
                //this.getConnectionId();
            } catch (err) {
                console.log(err);
                setTimeout(start, 5000);
            }
        };

        this.connection.onclose(async () => {
            await start();
        });*/

        // Start the connection.
        this.start();


        // Register events
        this.connection.on('ReceiveMessage', (data: ProgramMessage) => {

            // If data has capitalized property names, convert them to camelcase and create a proper ProgramMessage object
            data = this.convertToCamelCase(data);
            console.log("Received Message: " + data.messageText + " with type: " + data.messageType);
               
            // if message type is feedback, then update the feedback status array
            if (data.messageType == "Feedback") {

                var fbstatus = new FeedbackStatus();
                fbstatus.name = data.feedbackStatus;
                fbstatus.messageText = data.messageText;
                fbstatus.status = true;

                // Add the feedback status to the current feedback status array - dont overwrite
                var fbstatusarray = this.CurrentFeedbackStatusArray.getValue();
                if (fbstatusarray.feedbackStatuses == null) {
                    fbstatusarray.feedbackStatuses = [];
                }
                fbstatusarray.feedbackStatuses.push(fbstatus);

                // Output the feedback status to the console
                console.log("Feedback Status: " + fbstatus.name);

                // Emit the feedback status message
                this.feedbackMessageReceived.emit(fbstatus);

            } else {
                // Emit any general type messages
                this.messageReceived.emit(data);
            }

        });
        
    }

    public updateStartingPoints(data) {
        this.pstartingPoints.next(data);
    }

    public getStartingPoints() {
        return this.pstartingPoints.getValue();
    }

    public updateTalkingPoints(data) {
        this.ptalkingPoints.next(data);
    }

    public getTalkingPoints() {
        return this.ptalkingPoints.getValue();
    }

    public getCurrentScenarioRec() {
        return this.CurrentScenarioRec.getValue();
    }


    private convertToCamelCase(data: any): ProgramMessage {

        var pm = new ProgramMessage();

        // All data property names are capitalized.  Convert them to camel case.  Do this assuming all the property names in the ProgramMessage class.

        pm.partitionKey = data.PartitionKey;
        pm.rowKey = data.RowKey;
        pm.userId = data.UserId;
        pm.userName = data.UserName;
        pm.programId = data.ProgramId;
        pm.authorId = data.AuthorId;
        pm.authorName = data.AuthorName;
        pm.messageType = data.MessageType;
        pm.messageDate = data.MessageDate;
        pm.messageNumber = data.MessageNumber;
        pm.entityType = data.EntityType;
        pm.messageText = data.MessageText;
        pm.status = data.Status;
        pm.aspectId = data.AspectId;
        pm.scenarioId = data.ScenarioId;
        pm.aspectName = data.AspectName;
        pm.scenarioName = data.ScenarioName;
        pm.responseFileName = data.ResponseFileName;
        pm.responseFileThumb = data.ResponseFileThumb;
        pm.resourceURL = data.ResourceURL;
        pm.resourceThumbURL = data.ResourceThumbURL;
        pm.streamForUserName = data.StreamForUserName;
        pm.imageURL = data.ImageURL;
        pm.feedbackStatus = data.FeedbackStatus;
        pm.scenarioRecommendationId = data.ScenarioRecommendationId;
        pm.programName = data.ProgramName;
        pm.roundId = data.RoundId;
        pm.roundNum = data.RoundNum;
        pm.responseType = data.ResponseType;
        pm.responseId = data.ResponseId;
        pm.engagementType = data.EngagementType;
        pm.isNew = data.IsNew;

        return pm;
    }

    // USER NOTES ------------------------------------------------------------------------------------------
    GetUserNotes(scenarioId: string) {
        var gs = this.GetCurrentUser();

        var userNotesItem = new UserNotesItem();
        userNotesItem.userName = gs.username;
        userNotesItem.scenarioId = scenarioId;

        var addurl = this.baseURL + 'api/neo/getusernotes';
        var retval = this.http.post<UserNotesDto>(addurl, userNotesItem);
        return retval;
    }

    AddUserNote(userNotesItem: UserNotesItem) {
        var gs = this.GetCurrentUser();
        userNotesItem.userName = gs.username;

        var addurl = this.baseURL + 'api/neo/addusernote';
        var retval = this.http.post<UserNotesDto>(addurl, userNotesItem);
        return retval;
    }

    EditUserNote(userNotesItem: UserNotesItem) {
        var gs = this.GetCurrentUser();
        userNotesItem.userName = gs.username;

        var addurl = this.baseURL + 'api/neo/editusernote';
        var retval = this.http.post<UserNotesDto>(addurl, userNotesItem);
        return retval;
    }

    // RATINGS ------------------------------------------------------------------------------------------
    RateScenario(rating: Rating) {
        var gs = this.GetCurrentUser();
        rating.userName = gs.username;

        var addurl = this.baseURL + 'api/neo/ratescenario';
        var retval = this.http.post<UserNotesDto>(addurl, rating);
        return retval;
    }



    // MESSAGING ------------------------------------------------------------------------------------------
    private start() {
        this.thenable = this.connection.start()
            .finally(() => {
                console.log('SignalR connection started!');
                this.getConnectionId();
            })
            //.then(() => this.getConnectionId())
            .catch(err => {
            console.error("The Start Connection ERROR IS: " + err.toString());
        });
    }

    public getConnectionId = () => {
        this.connection.invoke('GetConnectionId').then(
            (data) => {
                this.userConnectionID = data;
                //this.userConnectionID.next(data);
                console.log('Connection ID is: ', data);
            }
        );
    }

    // INIT ------------------------------------------------------------------------------------------
    ngOnInit(): void {

    }

    public logOut() {

        this.isSignedIn.next(false);

        // Reset other user related fields
        this.CurrentScenarioRec.next(new ScenarioRecommendation());
        this.CurrentScenario.next(new Scenario());
        this.CurrentResponse.next(new UserResponse());
        this.VideoSource.next("");
        this.VideoSourceThumb.next("");
        this.NewResponse.next(new UserResponse());
        this.CurrentFeedbackStatusArray.next(new FeedbackStatusArray());
        this.showScenarioMenu.next(true);
        this.showGuidanceBar.next(true);
        this.profiledetail.next(new UserProfile());
        this.onboardingStep.next(1);
        this.isOnboarding.next(false);
        this.navigationCreated.next(false);
        this.isResponseScreen.next(false);
        this.isTrialRegistration.next(false);
        this.userType.next("");
        this.trialUserDto.next(new UserDto());
        this.isRegistered.next(false);
        this.menuAction.next("");
        this.showStartingPointsButton = false;
        this.showStartingPoints = false;
        this.showTalkingPoints = false;
        this.showGuidanceForm = false;
        this.showTalkTrackButton = false;
        this.showTryButton = false;
        this.expandGuidanceForm = false;
        this.expandStartingPoints = false;
        this.updateStartingPoints([]);
        this.updateTalkingPoints([]);




    }

    // REGISTRATION ------------------------------------------------------------------------------------------

    public signUpOTPCheck(regmodel: LoginDto) {

        var addurl = this.baseURL + 'api/neo/signUpOTPCheck';
        var retval = this.http.post<LoginDto>(addurl, regmodel);
        return retval;

    }

    public resendOTP(regmodel: LoginDto) {

        var addurl = this.baseURL + 'api/neo/resendOTP';
        var retval = this.http.post<LoginDto>(addurl, regmodel);
        return retval;

    }


    // SUPER ADMIN ------------------------------------------------------------------------------------------
    public createRoles() {
        var gs = new GenericString();
        var addurl = this.baseURL + 'api/neo/createroles';
        var retval = this.http.post<LoadResult[]>(addurl, gs);
        return retval;
    }

    public GetAdminProfile() {
        var gs = this.GetCurrentUser();
        var addurl = this.baseURL + 'api/neo/getadminprofile';
        var retval = this.http.post<AdminProfileDto>(addurl, gs);
        return retval;
    }

    AssignUsersToCoaches(selectedUsers: UserProfile[], selectedCoaches: UserProfile[]) {
        var gs = this.GetCurrentUser();

        var admindto = new AdminProfileDto();
        admindto.users = selectedUsers;
        admindto.coaches = selectedCoaches;
        admindto.userName = gs.username;
        admindto.accessToken = gs.token;

        var addurl = this.baseURL + 'api/neo/assignuserstocoaches';
        var retval = this.http.post<AdminProfileDto>(addurl, admindto);
        return retval;
    }

    GetCoachesForUser(user: UserProfile) {
        var gs = this.GetCurrentUser();
        var agdto = new AdminGenericDto();
        agdto.username = gs.username;
        agdto.token = gs.token;
        agdto.user = user;
        agdto.continuationToken = gs.token;
        agdto.item = gs.username;

        var addurl = this.baseURL + 'api/neo/getcoachesforuser';
        var retval = this.http.post<AdminProfileDto>(addurl, agdto);
        return retval;
    }

    GetUsersForCoach(coach: UserProfile) {
        var gs = this.GetCurrentUser();
        var agdto = new AdminGenericDto();
        agdto.username = gs.username;
        agdto.token = gs.token;
        agdto.coach = coach;
        agdto.continuationToken = gs.token;
        agdto.item = gs.username;

        var addurl = this.baseURL + 'api/neo/getusersforcoach';
        var retval = this.http.post<AdminProfileDto>(addurl, agdto);
        return retval;
    }
    // GRAPH ADMIN ------------------------------------------------------------------------------------------
    GetAdminAspects() {
        var gs = this.GetCurrentUser();
        var addurl = this.baseURL + 'api/neo/getadminaspects';
        var retval = this.http.post<AdminProfileDto>(addurl, gs);
        return retval;
    }

    UpdateAspect(aspect: Aspect) {
        var gs = this.GetCurrentUser();
        var addurl = this.baseURL + 'api/neo/updateaspect';
        var retval = this.http.post<Aspect>(addurl, aspect);
        return retval;
    }

    GetRelatedAspects(aspectId: string) {
        var gs = this.GetCurrentUser();
        gs.item = aspectId;
        var addurl = this.baseURL + 'api/neo/getrelatedaspects';
        var retval = this.http.post<BaseAspectsGraph>(addurl, gs);
        return retval;
    }

    UpdateAspectEdges(edges: Edge[]) {
        var bag = new BaseAspectsGraph();
        bag.aspects = null;
        bag.edges = edges;
        var addurl = this.baseURL + 'api/neo/updateaspectedges';
        var retval = this.http.post<BaseAspectsGraph>(addurl, bag);
        return retval;
    }

    GetAdminScenarios() {
        var gs = this.GetCurrentUser();
        var addurl = this.baseURL + 'api/neo/getadminscenarios';
        var retval = this.http.post<AdminProfileDto>(addurl, gs);
        return retval;
    }

    GetScenarioRubrics(scenarioId: string) {
        var gs = this.GetCurrentUser();
        gs.item = scenarioId;
        var addurl = this.baseURL + 'api/neo/getscenariorubrics';
        var retval = this.http.post<AdminRubricDto>(addurl, gs);
        return retval;
    }

    UpdateScenario(scenario: Scenario, scenarioFile: File, thumbnail: string) {
        var gs = this.GetCurrentUser();
        var token = this.GetCurrentUserToken();

        // Must send data as Form data since it includes a file
        const formData = new FormData();
        formData.append('token', token);
        formData.append("userConnectionId", this.userConnectionID);

        // Append the scenario attributes
        formData.append("scenarioDto[id]", scenario.id);
        formData.append("scenarioDto[name]", scenario.name);
        formData.append("scenarioDto[description]", scenario.description);
        formData.append("scenarioDto[scenarioIntent]", scenario.scenarioIntent);
        formData.append("scenarioDto[title]", scenario.title);
        formData.append("scenarioDto[format]", scenario.format);
        formData.append("scenarioDto[difficulty]", scenario.difficulty);
        formData.append("scenarioDto[estEngagement]", scenario.estEngagement.toString());
        formData.append("scenarioDto[estTimeInvestment]", scenario.estTimeInvestment.toString());
        formData.append("scenarioDto[estMoneyInvestment]", scenario.estMoneyInvestment.toString());
        formData.append("scenarioDto[estEnergyInvestment]", scenario.estEnergyInvestment.toString());
        formData.append("scenarioDto[scenarioType]", scenario.scenarioType);
        formData.append("scenarioDto[indexed]", scenario.indexed.toString());
        formData.append("scenarioDto[sequence]", scenario.sequence.toString());
        formData.append("scenarioDto[userLevel]", scenario.userLevel);
        formData.append("scenarioDto[practiceRequired]", scenario.practiceRequired.toString());
        formData.append("scenarioDto[uC_PublicSpeaking]", scenario.uC_PublicSpeaking.toString());
        formData.append("scenarioDto[uC_Interviews]", scenario.uC_Interviews.toString());
        formData.append("scenarioDto[uC_Teaching_and_training]", scenario.uC_Teaching_and_training.toString());
        formData.append("scenarioDto[uC_Sales_marketing]", scenario.uC_Sales_marketing.toString());
        formData.append("scenarioDto[uC_Social_and_Networking]", scenario.uC_Social_and_Networking.toString());
        formData.append("scenarioDto[notes]", scenario.notes);
        formData.append("scenarioDto[resourceURL]", scenario.resourceURL);
        formData.append("scenarioDto[transcriptURL]", scenario.transcriptURL);
        formData.append("scenarioDto[transcript]", scenario.transcript);
        formData.append("scenarioDto[scenarioNum]", scenario.scenarioNum);

        if ((scenarioFile != null) && (scenarioFile != undefined)) {
            formData.append('scenarioFile', scenarioFile, scenarioFile.name);
            formData.append("scenarioDto[scenarioFileName]", scenarioFile.name);
        }

        if ((thumbnail != null) && (thumbnail != undefined)) {
            formData.append('thumbnail', thumbnail);
        }

        var addurl = this.baseURL + 'api/neo/updatescenario';
        var retval = this.http.post<Scenario>(addurl, formData);
        return retval;
    }

    UpdateScenarioURLs(scenarioId: string) {
        var gs = this.GetCurrentUser();
        gs.item = scenarioId;
        var addurl = this.baseURL + 'api/neo/updatescenariourls';
        var retval = this.http.post<boolean>(addurl, gs);
        return retval;
    }

    ClearScenarioResources(scenarioId: string) {
        var gs = this.GetCurrentUser();
        gs.item = scenarioId;
        var addurl = this.baseURL + 'api/neo/clearscenarioresources';
        var retval = this.http.post<boolean>(addurl, gs);
        return retval;
    }


    UpdateRubric(rubric: Rubric) {
        var gs = this.GetCurrentUser();
        var token = this.GetCurrentUserToken();

        var rdto = new RubricDto();
        rdto.rubric = rubric;

        var addurl = this.baseURL + 'api/neo/updaterubric';
        var retval = this.http.post<RubricDto>(addurl, rdto);
        return retval;
    }

    // CURRICULUM ADMIN ------------------------------------------------------------------------------------------
    GetProgramCurriculum() {
        var gs = this.GetCurrentUser();
        var addurl = this.baseURL + 'api/neo/getprogramcurriculum';
        var retval = this.http.post<ProgramCurriculum>(addurl, gs);
        return retval;
    }
    AddCurriculum(curriculum: Curriculum) {
        var gs = this.GetCurrentUser();
        var addurl = this.baseURL + 'api/neo/addcurriculum';
        var x = curriculum;

        var retval = this.http.post<Curriculum>(addurl, curriculum);
        return retval;

    }
    // END CURRICULUM ADMIN ------------------------------------------------------------------------------------------



    /* TRAINER FUNCTIONS ------------------------------------------------------------------------------------------ */
    GetScenarioTranscript(scenarioId: string, transcriptURL: string) {
        var gs = this.GetCurrentUser();
        gs.item = scenarioId;
        gs.secondItem = transcriptURL;
        var addurl = this.baseURL + 'api/neo/getscenariotranscript';
        var retval = this.http.post<ScenarioTrainerDto>(addurl, gs);
        return retval;
    }

    GetSyntheticScenResponse(scenarioId: string, transcriptURL: string) {
        var gs = this.GetCurrentUser();
        gs.item = scenarioId;
        gs.secondItem = transcriptURL;
        var addurl = this.baseURL + 'api/neo/getsyntheticscenresponse';
        var retval = this.http.post<ScenarioTrainerDto>(addurl, gs);
        return retval;
    }

    TestSyntheticScenResponse(scenario: Scenario, syntheticResponse: string) {
        var gs = this.GetCurrentUser();
        var token = this.GetCurrentUserToken();

        // Must send data as Form data since it includes a file
        const formData = new FormData();
        formData.append('token', token);
        formData.append("userConnectionId", this.userConnectionID);

        // Append the scenario attributes
        formData.append("scenarioTrainerDto[id]", scenario.id);
        formData.append("scenarioTrainerDto[name]", scenario.name);
        formData.append("scenarioTrainerDto[transcriptURL]", scenario.transcriptURL);
        formData.append("scenarioTrainerDto[syntheticResponse]", syntheticResponse);
        formData.append("scenarioTrainerDto[username]", gs.username);

        var addurl = this.baseURL + 'api/neo/testsyntheticscenresponse';
        var retval = this.http.post<FeedbackOutput[]>(addurl, formData);
        return retval;
    }

    SaveSyntheticEvaluations(selectedItem: Scenario, syntheticResponse: string, feedbackOutputs: FeedbackOutput[]) {

    }




    /* END TRAINER FUNCTIONS ------------------------------------------------------------------------------------------ */

    SetThumbnail(scenarioId: string, thumbnail: string) {
        var gs = this.GetCurrentUser();
        gs.item = scenarioId;
        gs.secondItem = thumbnail;
        var addurl = this.baseURL + 'api/neo/setthumbnail';
        var retval = this.http.post<Scenario>(addurl, gs);
        return retval;
    }

    IndexScenario(scenarioId: string) {
        var gs = this.GetCurrentUser();
        gs.item = scenarioId;
        var addurl = this.baseURL + 'api/neo/indexscenario';
        var retval = this.http.post<Scenario>(addurl, gs);
        return retval;
    }

    GetRelatedAspectsForScenario(scenarioId: string) {
        var gs = this.GetCurrentUser();
        gs.item = scenarioId;
        var addurl = this.baseURL + 'api/neo/getrelatedaspectsforscenario';
        var retval = this.http.post<BaseAspectsGraph>(addurl, gs);
        return retval;
    }

    UpdateScenarioEdges(edges: Edge[]) {
        var bag = new BaseAspectsGraph();
        bag.scenarios = null;
        bag.edges = edges;
        var addurl = this.baseURL + 'api/neo/updatescenarioedges';
        var retval = this.http.post<BaseAspectsGraph>(addurl, bag);
        return retval;
    }

    DeleteAspect(aspectId: string) {
        var gs = this.GetCurrentUser();
        gs.item = aspectId;
        var addurl = this.baseURL + 'api/neo/deleteaspect';
        var retval = this.http.post<boolean>(addurl, gs);
        return retval;
    }

    DeleteScenario(scenarioId: string) {
        var gs = this.GetCurrentUser();
        gs.item = scenarioId;
        var addurl = this.baseURL + 'api/neo/deletescenario';
        var retval = this.http.post<boolean>(addurl, gs);
        return retval;
    }

    DeleteRubric(scenarioId: string, rubricId: string) {
        var gs = this.GetCurrentUser();
        gs.item = scenarioId;
        gs.secondItem = rubricId;
        var addurl = this.baseURL + 'api/neo/deleterubric';
        var retval = this.http.post<boolean>(addurl, gs);
        return retval;
    }

    // USER MANAGEMENT ------------------------------------------------------------------------------------------

    // Set user type
    SetUserType(userType: string) {

        this.userType.next(userType);
    }

    IsTrialUser(): boolean {

        if (this.userType.getValue() == "TrialUser") {
            return true;
        } else {
            return false;
        }
    }

    SetOnboarding(isOnboarding: boolean) {
        console.log("Setting Onboarding in SMService to: " + isOnboarding);
        this.isOnboarding.next(isOnboarding);
    }

    // Get Current User
    GetCurrentUser(): GenericString {
        var gs = new GenericString();

        // Get user from authenticated user
        gs.item = "";
        gs.username = this.csUserName;
        gs.token = this.GetCurrentUserToken();
        gs.continuationToken = "";
        gs.responseFile = null;
        gs.streamForUser = gs.username;
        gs.userType = "User";

        // TODO - Remove this hack
        if (gs.username == undefined) {
            //gs.username = this.localStorageGetItem('username');
        }

        return gs;
    }

    get csUserName(): string {
        var localuser = localStorage.getItem('csUser') ?? '';
        return localuser;
    }

    // Get Current User Token
    GetCurrentUserToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }


    // USER PROFILE ------------------------------------------------------------------------------------------
    SubmitProfileInfo(profile: UserProfile, aspectPreferencesDto: AspectPreferencesDto) {

        // TODO - Refactor - this was a hack due to error.  Ensure all values in profile are set and not null
        var userProfileDto = new UserProfileDto();
        userProfileDto.id = profile.id;
        userProfileDto.name = profile.name;
        userProfileDto.description = "";
        userProfileDto.entityType = profile.entityType;
        userProfileDto.imageURL = profile.imageURL;
        userProfileDto.userStatus = profile.userStatus;
        userProfileDto.basicProfileComplete = profile.basicProfileComplete;
        userProfileDto.programSettingsComplete = profile.programSettingsComplete;
        userProfileDto.firstMessageComplete = profile.firstMessageComplete;
        userProfileDto.firstRecommendationComplete = profile.firstRecommendationComplete;
        userProfileDto.programStartDate = profile.programStartDate;
        userProfileDto.engagementType = profile.engagementType;
        userProfileDto.engSubmitResponse = profile.engSubmitResponse;
        userProfileDto.engSubmitResponseDate = profile.engSubmitResponseDate;
        userProfileDto.engReminder = profile.engReminder;
        userProfileDto.engReminderDate = profile.engReminderDate;
        userProfileDto.engTotalBudget = profile.engTotalBudget;
        userProfileDto.recConfigID = profile.recConfigID;
        userProfileDto.requestedHumanReview = profile.requestedHumanReview;
        userProfileDto.engPersonalityType = profile.engPersonalityType;
        userProfileDto.engPriorExperience = profile.engPriorExperience;
        userProfileDto.engPriorExperienceDate = "";
        userProfileDto.engCoachingPreference = profile.engCoachingPreference;
        userProfileDto.engTimeAvailable = profile.engTimeAvailable;
        userProfileDto.engTimePerScenario = profile.engTimePerScenario;
        userProfileDto.engJudgingType = profile.engJudgingType;
        userProfileDto.engReminderFrequency = profile.engReminderFrequency;
        userProfileDto.engReminderDays = profile.engReminderDays;
        userProfileDto.engReminderWeeks = profile.engReminderWeeks;
        userProfileDto.engReminderMonths = profile.engReminderMonths;
        userProfileDto.engExpressInterest = profile.engExpressInterest;
        userProfileDto.engExpressInterestDate = profile.engExpressInterestDate;
        userProfileDto.engViewRead = profile.engViewRead;
        userProfileDto.engViewReadDate = profile.engViewReadDate;
        userProfileDto.engAnswerAssessment = profile.engAnswerAssessment;
        userProfileDto.engAnswerAssessmentDate = profile.engAnswerAssessmentDate;
        userProfileDto.engReadStrategy = profile.engReadStrategy;
        userProfileDto.engReadStrategyDate = profile.engReadStrategyDate;
        userProfileDto.engPracticeStrategy = profile.engPracticeStrategy;
        userProfileDto.engPracticeStrategyDate = profile.engPracticeStrategyDate;
        userProfileDto.engDevelopHabit = profile.engDevelopHabit;
        userProfileDto.engDevelopHabitDate = profile.engDevelopHabitDate;
        userProfileDto.engImplementResource = profile.engImplementResource;
        userProfileDto.engImplementResourceDate = profile.engImplementResourceDate;
        userProfileDto.engCoachRating = profile.engCoachRating;
        userProfileDto.engCoachRatingDate = profile.engCoachRatingDate;
        userProfileDto.engCommunityParticipation = profile.engCommunityParticipation;
        userProfileDto.engCommunityParticipationDate = profile.engCommunityParticipationDate;
        userProfileDto.engUpdateAspect = profile.engUpdateAspect;
        userProfileDto.engUpdateAspectDate = profile.engUpdateAspectDate;
        userProfileDto.engUpdateScenario = profile.engUpdateScenario;
        userProfileDto.engUpdateScenarioDate = profile.engUpdateScenarioDate;
        userProfileDto.engAdoptProgram = profile.engAdoptProgram;
        userProfileDto.engAdoptProgramDate = profile.engAdoptProgramDate;
        userProfileDto.engFeedback = profile.engFeedback;
        userProfileDto.engFeedbackDate = profile.engFeedbackDate;
        userProfileDto.engSubmitResponse = profile.engSubmitResponse;
        userProfileDto.engSubmitResponseDate = profile.engSubmitResponseDate;
        userProfileDto.engReminder = profile.engReminder;
        userProfileDto.engReminderDate = profile.engReminderDate;
        userProfileDto.jobFunction = profile.jobFunction;
        userProfileDto.industry = profile.industry;
        userProfileDto.engPrimaryMotivation = profile.engPrimaryMotivation;

        var recConfigDto = new RecConfigDto();
        recConfigDto.id = profile.recConfig.id;
        recConfigDto.name = profile.recConfig.name;
        recConfigDto.description = profile.recConfig.description;
        recConfigDto.entityType = profile.recConfig.entityType;
        recConfigDto.aspectGraphWeight = profile.recConfig.aspectGraphWeight;
        recConfigDto.engagementWeight = profile.recConfig.engagementWeight;
        recConfigDto.usageIntentionOneWeight = profile.recConfig.usageIntentionOneWeight;
        recConfigDto.usageIntentionTwoWeight = profile.recConfig.usageIntentionTwoWeight;
        recConfigDto.usageIntentionThreeWeight = profile.recConfig.usageIntentionThreeWeight;
        recConfigDto.priorExperienceWeight = profile.recConfig.priorExperienceWeight;
        recConfigDto.timeAvailableWeight = profile.recConfig.timeAvailableWeight;
        recConfigDto.timePerScenarioWeight = profile.recConfig.timePerScenarioWeight;
        recConfigDto.personalityTypeWeight = profile.recConfig.personalityTypeWeight;
        recConfigDto.coverageWeight = profile.recConfig.coverageWeight;
        recConfigDto.masteryWeight = profile.recConfig.masteryWeight;
        recConfigDto.progressWeight = profile.recConfig.progressWeight;
        recConfigDto.discoverWeight = profile.recConfig.discoverWeight;
        recConfigDto.discoverNewnessWeight = profile.recConfig.discoverNewnessWeight;
        recConfigDto.strengthsWeight = profile.recConfig.strengthsWeight;
        recConfigDto.journeyWeight = profile.recConfig.journeyWeight;
        recConfigDto.priorityWeight = profile.recConfig.priorityWeight;
        recConfigDto.userName = profile.recConfig.userName;
        recConfigDto.userId = profile.recConfig.userId;
        recConfigDto.programId = profile.recConfig.programId;
        recConfigDto.roundId = profile.recConfig.roundId;



        var profileDto = new SimpleProfileUpdateDto();
        profileDto.userProfileDto = userProfileDto;
        profileDto.recConfigDto = recConfigDto;
        profileDto.aspectPreferencesDto = aspectPreferencesDto;
        profileDto.userName = profile.name;
        profileDto.accessToken = this.GetCurrentUserToken();

        var addurl = this.baseURL + 'api/neo/submitprofileinfo';
        var retval = this.http.post<boolean>(addurl, profileDto);
        return retval;
    }

    SubmitConfigInfo(recconfig: RecConfig) {
        var recConfigDto = new RecConfigDto();
        //recConfigDto.recConfig = recconfig;
        //recConfigDto.token = this.GetCurrentUserToken();

        var addurl = this.baseURL + 'api/neo/submitconfiginfo';
        var retval = this.http.post<boolean>(addurl, recconfig);
        return retval;
    }

    /*SubmitProgramSettings() {
        var recConfigDto = new RecConfigDto();
        recConfigDto.token = this.GetCurrentUserToken();

        var addurl = this.baseURL + 'api/neo/submitprogramsettings';
        var retval = this.http.post<boolean>(addurl, recConfigDto);
        return retval;
    }*/

    // USER FUNCTIONS ------------------------------------------------------------------------------------------
    GetNextRec() {
        var addurl = this.baseURL + 'api/neo/getnextrec';

        var messageDto = new MessageDto();
        messageDto.token = this.GetCurrentUserToken();
        messageDto.programMessageDto = this.programstream.messages[0];
        messageDto.userConnectionId = this.userConnectionID;

        var retval = this.http.post<ProgramMessage>(addurl, messageDto);
        return retval;
    }

    // Temporarily saves a user's response to a scenario
    SaveVideo(videoPreviewUrl: string, thumbnailData: string, videoFile: File) {

        this.VideoSource.next(videoPreviewUrl);
        this.VideoSourceThumb.next(thumbnailData);
        this.VideoFile.next(videoFile);

        console.log("SaveVideo in SMService called");
        console.log("Video Preview URL: " + this.VideoSource.value);
        console.log("Video File: " + this.VideoFile.value.name);
        console.log("Thumbnail Data Saved as: " + this.VideoSourceThumb.value);

    }


    /* Older function to send a message in the chat along with a video response */
    SendProgramMessage(programMessage: ProgramMessage, videoFile: File) {
        /*var messageDto = new MessageDto();
        messageDto.token = this.GetCurrentUserToken();
        messageDto.userConnectionId = this.userConnectionID;
        messageDto.responseFile = videoFile;
        messageDto.programMessageDto = programMessage;
        */
        var addurl = this.baseURL + 'api/neo/sendprogrammessage';
        //var retval = this.http.post<ProgramMessage>(addurl, messageDto);


        var token = this.GetCurrentUserToken();

        // Must send data as Form data since it includes a file
        const formData = new FormData();
        formData.append('token', token);
        formData.append('userConnectionId', this.userConnectionID);

        if ((videoFile != null) && (videoFile != undefined)) {
            formData.append('responseFile', videoFile, videoFile.name);
        }

        // Serialize programMessage
        //var programMessageJson = JSON.stringify(programMessage);
        //formData.append('programMessageDto', programMessageJson);

        formData.append("programMessageDto[partitionKey]", programMessage.partitionKey);
        formData.append("programMessageDto[rowKey]", programMessage.rowKey);
        formData.append("programMessageDto[userName]", programMessage.userName);
        formData.append("programMessageDto[userId]", programMessage.userId);
        formData.append("programMessageDto[programId]", programMessage.programId);
        formData.append("programMessageDto[authorName]", programMessage.authorName);
        formData.append("programMessageDto[authorId]", programMessage.authorId);
        formData.append("programMessageDto[messageNumber]", programMessage.messageNumber.toString());
        formData.append("programMessageDto[messageText]", programMessage.messageText);
        formData.append("programMessageDto[messageType]", programMessage.messageType);
        formData.append("programMessageDto[entityType]", programMessage.entityType);
        formData.append("programMessageDto[status]", programMessage.status);
        formData.append("programMessageDto[aspectId]", programMessage.aspectId);
        formData.append("programMessageDto[scenarioId]", programMessage.scenarioId);
        formData.append("programMessageDto[aspectName]", programMessage.aspectName);
        formData.append("programMessageDto[scenarioName]", programMessage.scenarioName);
        formData.append("programMessageDto[responseFileName]", programMessage.responseFileName);
        //formData.append("programMessageDto[imageURL]", programMessage.imageURL);


        var retval = this.http.post<ProgramMessage>(addurl, formData);
        return retval;

        /*this.http.post(addurl, formData).subscribe((response) => {
            console.log(response);
            return response;
        });*/
    }

    /* Newer function to send just a video response */
    SubmitVideoResponse(programMessage: ProgramMessage, videoFile: File) {

        var addurl = this.baseURL + 'api/neo/submitvideoresponse';
        var token = this.GetCurrentUserToken();

        // Must send data as Form data since it includes a file
        const formData = new FormData();
        formData.append('token', token);
        formData.append('userConnectionId', this.userConnectionID);

        if ((videoFile != null) && (videoFile != undefined)) {
            formData.append('responseFile', videoFile, videoFile.name);
            formData.append("programMessageDto[responseFileThumb]", programMessage.responseFileThumb);
        }

        formData.append("programMessageDto[partitionKey]", programMessage.partitionKey);
        formData.append("programMessageDto[rowKey]", programMessage.rowKey);
        formData.append("programMessageDto[userName]", programMessage.userName);
        formData.append("programMessageDto[userId]", programMessage.userId);
        formData.append("programMessageDto[programId]", programMessage.programId);
        formData.append("programMessageDto[authorName]", programMessage.authorName);
        formData.append("programMessageDto[authorId]", programMessage.authorId);
        formData.append("programMessageDto[messageNumber]", programMessage.messageNumber.toString());
        formData.append("programMessageDto[messageText]", programMessage.messageText);
        formData.append("programMessageDto[messageType]", programMessage.messageType);
        formData.append("programMessageDto[entityType]", programMessage.entityType);
        formData.append("programMessageDto[status]", programMessage.status);
        formData.append("programMessageDto[aspectId]", programMessage.aspectId);
        formData.append("programMessageDto[scenarioId]", programMessage.scenarioId);
        formData.append("programMessageDto[aspectName]", programMessage.aspectName);
        formData.append("programMessageDto[scenarioName]", programMessage.scenarioName);
        formData.append("programMessageDto[responseFileName]", programMessage.responseFileName);

        formData.append("programMessageDto[programName]", programMessage.programName);
        formData.append("programMessageDto[roundId]", programMessage.roundId);
        formData.append("programMessageDto[roundNum]", programMessage.roundNum.toString());
        formData.append("programMessageDto[feedbackStatus]", programMessage.feedbackStatus);
        formData.append("programMessageDto[scenarioRecommendationId]", programMessage.scenarioRecommendationId);
        formData.append("programMessageDto[responseType]", programMessage.responseType);
        formData.append("programMessageDto[responseId]", programMessage.responseId);

        const upload$ = this.http.post<UserResponse>(addurl, formData, {
            reportProgress: true,
            observe: 'events'
        })
        .pipe(
            finalize(() => {

                // Clear the progress bar
                this.uploadProgress = null;
                this.uploadSub = null;
            }
        ));

        /*this.uploadSub = upload$.subscribe(event => {
            if (event.type == HttpEventType.UploadProgress) {
                var progress = Math.round(100 * (event.loaded / event.total));
                console.log("Progress: " + progress + "%");

                // Wrap the progress into the uploadProgress observable
                this.uploadProgress.next(progress);
                //console.log("UploadProgress is: " + this.uploadProgress);
            }
        })*/

        return upload$;
    }

    RequestGuidanceStart(programMessage: ProgramMessage, guidanceFields: ScenarioGuidanceDto) {

        var token = this.GetCurrentUserToken();

        // Must send data as Form data since it includes a file
        const formData = new FormData();
        formData.append('token', token);
        formData.append('userConnectionId', this.userConnectionID);
        formData.append("programMessageDto[partitionKey]", programMessage.partitionKey);
        formData.append("programMessageDto[rowKey]", programMessage.rowKey);
        formData.append("programMessageDto[userName]", programMessage.userName);
        formData.append("programMessageDto[userId]", programMessage.userId);
        formData.append("programMessageDto[programId]", programMessage.programId);
        formData.append("programMessageDto[authorName]", programMessage.authorName);
        formData.append("programMessageDto[authorId]", programMessage.authorId);
        formData.append("programMessageDto[messageNumber]", programMessage.messageNumber.toString());
        formData.append("programMessageDto[messageText]", programMessage.messageText);
        formData.append("programMessageDto[messageType]", programMessage.messageType);
        formData.append("programMessageDto[entityType]", programMessage.entityType);
        formData.append("programMessageDto[status]", programMessage.status);
        formData.append("programMessageDto[aspectId]", programMessage.aspectId);
        formData.append("programMessageDto[scenarioId]", programMessage.scenarioId);
        formData.append("programMessageDto[aspectName]", programMessage.aspectName);
        formData.append("programMessageDto[scenarioName]", programMessage.scenarioName);
        formData.append("programMessageDto[responseFileName]", programMessage.responseFileName);
        formData.append("programMessageDto[programName]", programMessage.programName);
        formData.append("programMessageDto[roundId]", programMessage.roundId);

        if (programMessage.roundNum != undefined) {
            formData.append("programMessageDto[roundNum]", programMessage.roundNum.toString());
        }

        formData.append("programMessageDto[feedbackStatus]", programMessage.feedbackStatus);
        formData.append("programMessageDto[scenarioRecommendationId]", programMessage.scenarioRecommendationId);
        formData.append("programMessageDto[responseType]", programMessage.responseType);
        formData.append("programMessageDto[responseId]", programMessage.responseId);
        formData.append("programMessageDto[engagementType]", programMessage.engagementType);

        formData.append("scenarioGuidanceDto[industry]", guidanceFields.industry);
        formData.append("scenarioGuidanceDto[jobfunction]", guidanceFields.jobfunction);

        // Clear the progress bar
        this.uploadProgress = null;

        var addurl = this.baseURL + 'api/neo/requestguidancestart';
        var retval = this.http.post<ScenarioGuidanceDto>(addurl, formData);
        return retval;
    }

    RequestGuidanceTrack(programMessage: ProgramMessage, guidanceFields: ScenarioGuidanceDto) {

        var token = this.GetCurrentUserToken();

        // Must send data as Form data since it includes a file
        const formData = new FormData();
        formData.append('token', token);
        formData.append('userConnectionId', this.userConnectionID);
        formData.append("programMessageDto[partitionKey]", programMessage.partitionKey);
        formData.append("programMessageDto[rowKey]", programMessage.rowKey);
        formData.append("programMessageDto[userName]", programMessage.userName);
        formData.append("programMessageDto[userId]", programMessage.userId);
        formData.append("programMessageDto[programId]", programMessage.programId);
        formData.append("programMessageDto[authorName]", programMessage.authorName);
        formData.append("programMessageDto[authorId]", programMessage.authorId);
        formData.append("programMessageDto[messageNumber]", programMessage.messageNumber.toString());
        formData.append("programMessageDto[messageText]", programMessage.messageText);
        formData.append("programMessageDto[messageType]", programMessage.messageType);
        formData.append("programMessageDto[entityType]", programMessage.entityType);
        formData.append("programMessageDto[status]", programMessage.status);
        formData.append("programMessageDto[aspectId]", programMessage.aspectId);
        formData.append("programMessageDto[scenarioId]", programMessage.scenarioId);
        formData.append("programMessageDto[aspectName]", programMessage.aspectName);
        formData.append("programMessageDto[scenarioName]", programMessage.scenarioName);
        formData.append("programMessageDto[responseFileName]", programMessage.responseFileName);
        formData.append("programMessageDto[programName]", programMessage.programName);
        formData.append("programMessageDto[roundId]", programMessage.roundId);

        if (programMessage.roundNum != undefined) {
            formData.append("programMessageDto[roundNum]", programMessage.roundNum.toString());
        }

        formData.append("programMessageDto[feedbackStatus]", programMessage.feedbackStatus);
        formData.append("programMessageDto[scenarioRecommendationId]", programMessage.scenarioRecommendationId);
        formData.append("programMessageDto[responseType]", programMessage.responseType);
        formData.append("programMessageDto[responseId]", programMessage.responseId);
        formData.append("programMessageDto[engagementType]", programMessage.engagementType);

        formData.append("scenarioGuidanceDto[industry]", guidanceFields.industry);
        formData.append("scenarioGuidanceDto[jobfunction]", guidanceFields.jobfunction);

        // Include each starting point in the guidance fields
        for (var i = 0; i < guidanceFields.startingPoints.length; i++) {

            // Convert each starting point's fields to strings and append to the form data
            var startingPoint = guidanceFields.startingPoints[i];
            formData.append("scenarioGuidanceDto[startingPoints][" + i + "][number]", startingPoint.number.toString());
            formData.append("scenarioGuidanceDto[startingPoints][" + i + "][talkingPoint]", startingPoint.talkingPoint);
            formData.append("scenarioGuidanceDto[startingPoints][" + i + "][startingPoint]", startingPoint.startingPoint);
            formData.append("scenarioGuidanceDto[startingPoints][" + i + "][userComment]", startingPoint.userComment);

        }

        // Repeat for each talking point in the guidance fields
        for (var i = 0; i < guidanceFields.talkingPoints.length; i++) {

            // Convert each talking point's fields to strings and append to the form data
            var talkingPoint = guidanceFields.talkingPoints[i];
            formData.append("scenarioGuidanceDto[talkingPoints][" + i + "][number]", talkingPoint.number.toString());
            formData.append("scenarioGuidanceDto[talkingPoints][" + i + "][talkingPoint]", talkingPoint.talkingPoint);
            formData.append("scenarioGuidanceDto[startingPoints][" + i + "][startingPoint]", talkingPoint.startingPoint);
            formData.append("scenarioGuidanceDto[talkingPoints][" + i + "][userComment]", talkingPoint.userComment);

        }


        // Clear the progress bar
        this.uploadProgress = null;

        var addurl = this.baseURL + 'api/neo/requestguidancetrack';
        var retval = this.http.post<ScenarioGuidanceDto>(addurl, formData);
        return retval;
    }

    AcceptRec(programMessage: ProgramMessage) {
        var addurl = this.baseURL + 'api/neo/acceptrec';
        var messageDto = new MessageDto();
        messageDto.token = this.GetCurrentUserToken();
        messageDto.userConnectionId = this.userConnectionID;
        messageDto.programMessageDto = programMessage;

        var retval = this.http.post<ProgramMessage>(addurl, messageDto);
        return retval;
    }

    SkipRec(programMessage: ProgramMessage) {
        var addurl = this.baseURL + 'api/neo/skiprec';
        var messageDto = new MessageDto();
        messageDto.token = this.GetCurrentUserToken();
        messageDto.programMessageDto = programMessage;
        messageDto.userConnectionId = this.userConnectionID;

        var retval = this.http.post<ProgramMessage>(addurl, messageDto);
        return retval;
    }

    // TODO - these should pass the token for security
    DeleteMessage(programMessage: ProgramMessage) {
        var addurl = this.baseURL + 'api/neo/deletemessage';
        var retval = this.http.post<ProgramMessage>(addurl, programMessage);
        return retval;
    }

    GetNewRecommendation(programMessage: ProgramMessage) {
        var addurl = this.baseURL + 'api/neo/getnewrecommendation';
        var messageDto = new MessageDto();
        messageDto.token = this.GetCurrentUserToken();
        messageDto.programMessageDto = programMessage;
        messageDto.userConnectionId = this.GetConnectionId();
        
        var retval = this.http.post<ProgramMessage>(addurl, messageDto);
        return retval;
    }

    GetUserLastScenarioRec() {
        var gs = this.GetCurrentUser();
        gs.item = gs.username;
        gs.token = this.GetCurrentUserToken();
        var addurl = this.baseURL + 'api/neo/getuserlastscenariorec';

        var retval = this.http.post<ScenarioRecommendation>(addurl, gs);
        return retval;
    } 


    GetConnectionId():string {

        var id = "NO CONNECTION ID";
        if (this.userConnectionID != undefined && this.userConnectionID != null) {
            id = this.userConnectionID;
        }
        return id;
    }


    RequestHumanReview(programMessage: ProgramMessage) {
        var addurl = this.baseURL + 'api/neo/requesthumanreview';
        var token = this.GetCurrentUserToken();

        // Must send data as Form data since it includes a file
        const formData = new FormData();
        formData.append('token', token);
        formData.append('userConnectionId', this.userConnectionID);
        formData.append("programMessageDto[partitionKey]", programMessage.partitionKey);
        formData.append("programMessageDto[rowKey]", programMessage.rowKey);
        formData.append("programMessageDto[userName]", programMessage.userName);
        formData.append("programMessageDto[userId]", programMessage.userId);
        formData.append("programMessageDto[programId]", programMessage.programId);
        formData.append("programMessageDto[authorName]", programMessage.authorName);
        formData.append("programMessageDto[authorId]", programMessage.authorId);
        formData.append("programMessageDto[messageNumber]", programMessage.messageNumber.toString());
        formData.append("programMessageDto[messageText]", programMessage.messageText);
        formData.append("programMessageDto[messageType]", programMessage.messageType);
        formData.append("programMessageDto[entityType]", programMessage.entityType);
        formData.append("programMessageDto[status]", programMessage.status);
        formData.append("programMessageDto[aspectId]", programMessage.aspectId);
        formData.append("programMessageDto[scenarioId]", programMessage.scenarioId);
        formData.append("programMessageDto[aspectName]", programMessage.aspectName);
        formData.append("programMessageDto[scenarioName]", programMessage.scenarioName);
        formData.append("programMessageDto[responseFileName]", programMessage.responseFileName);

        var retval = this.http.post<ProgramMessage>(addurl, formData);
        return retval;

    }

    // TRIAL USER FUNCTIONS ------------------------------------------------------------------------------------------
    LoadTrialScenario() {
        var gs = this.GetCurrentUser();
        var addurl = this.baseURL + 'api/neo/gettrialscenario';
        console.log("LoadTrialScenario called: " + addurl);
        var retval = this.http.post<ScenarioRecommendation>(addurl, gs);
        return retval;
    }


    // LOADING FUNCTIONS ------------------------------------------------------------------------------------------
    GetUserProfile() {
        var gs = this.GetCurrentUser();
        var addurl = this.baseURL + 'api/neo/getuserprofile';
        var retval = this.http.post<SubmitProfileDto>(addurl, gs);

        // Save the user profile
        retval.subscribe((data) => {
            this.profiledetail.next(data.userProfile);
        });

        return retval;
    }

    GetRecConfig() {
        var gs = this.GetCurrentUser();
        var addurl = this.baseURL + 'api/neo/getrecconfig';
        var retval = this.http.post<SubmitProfileDto>(addurl, gs);
        return retval;
    }

    GetAspectPreferences () {
        var gs = this.GetCurrentUser();
        var addurl = this.baseURL + 'api/neo/getaspectpreferences';
        var retval = this.http.post<AspectPreferencesDto>(addurl, gs);
        return retval;
    }

    GetDashboard(streamForUser: UserProfile) {
        var gs = this.GetCurrentUser();
        gs.item = gs.username;

        // Regular users
        if ((streamForUser == null) || (streamForUser == undefined)) {
            gs.streamForUser = gs.username;
            gs.username = gs.username; // name of the coach or regular user
            gs.userType = "User";
        } else {
            // For coaches, need to pass in extra attribute of user to get stream for
            gs.streamForUser = streamForUser.name;
            gs.username = streamForUser.name;
            gs.userType = "Coach";
        }

        var addurl = this.baseURL + 'api/neo/getdashboard';
        var retval = this.http.post<Dashboard>(addurl, gs);
        return retval;
    }

    GetProgramStream(streamForUser: UserProfile, continuationToken: string)
    {
        var gs = this.GetCurrentUser();
        gs.continuationToken = continuationToken;
        gs.item = gs.username;

        // Regular users
        if ((streamForUser == null) || (streamForUser == undefined)) {
            gs.streamForUser = gs.username;
            gs.username = gs.username; // name of the coach or regular user
            gs.userType = "User";
        } else {
            // For coaches, need to pass in extra attribute of user to get stream for
            gs.streamForUser = streamForUser.name;
            gs.username = streamForUser.name;
            gs.userType = "Coach";
        }

        //if (gs.streamForUser != null && gs.streamForUser != undefined) {
            var addurl = this.baseURL + 'api/neo/getprogramstream';
            var retval = this.http.post<ProgramStream>(addurl, gs);
            return retval;
        //}

    }

    GetProgramSummary(streamForUser: UserProfile, continuationToken: string) {
        var gs = this.GetCurrentUser();
        gs.continuationToken = continuationToken;
        gs.item = gs.username;

        // Regular users
        if ((streamForUser == null) || (streamForUser == undefined)) {
            gs.streamForUser = gs.username;
            gs.username = gs.username; // name of the coach or regular user
            gs.userType = "User";
        } else {
            // For coaches, need to pass in extra attribute of user to get stream for
            gs.streamForUser = streamForUser.name;
            gs.username = streamForUser.name;
            gs.userType = "Coach";
        }

        var addurl = this.baseURL + 'api/neo/getprogramsummary';
        var retval = this.http.post<ProgramSummary>(addurl, gs);
        return retval;
    }

    EndUserOnboarding() {
        var gs = this.GetCurrentUser();
        gs.item = gs.username;

        var addurl = this.baseURL + 'api/neo/enduseronboarding';
        var retval = this.http.post<UserProfileDto>(addurl, gs);
        return retval;
    }


    GetScenariosForUser(streamForUser: UserProfile) {
        var gs = this.GetCurrentUser();
        gs.item = gs.username;
        gs.streamForUser = streamForUser.name;
        gs.username = streamForUser.name;
        gs.userType = "Coach";

        var addurl = this.baseURL + 'api/neo/getscenariosforuser';
        var retval = this.http.post<Scenario[]>(addurl, gs);
        return retval;
    }

    GetScenario(scenarioRec: ScenarioRecommendation) {
        var gs = this.GetCurrentUser();
        gs.item = gs.username;
        gs.secondItem = scenarioRec.programId;
        gs.thirdItem = scenarioRec.scenarioId;

        var addurl = this.baseURL + 'api/neo/getscenario';
        var retval = this.http.post<Scenario>(addurl, gs);
        return retval;
    }

    // TODO - this should be deprecated.
    GetFeedbackOutputs(scenarioRec: ScenarioRecommendation) {

        var gs = this.GetCurrentUser();
        gs.item = gs.username;
        gs.secondItem = scenarioRec.id;
        gs.thirdItem = scenarioRec.programId;

        var addurl = this.baseURL + 'api/neo/getscenariofeedback';
        var retval = this.http.post<FeedbackOutput[]>(addurl, gs)
        return retval;
    }

    GetFeedbackMaster(scenarioRec: ScenarioRecommendation, response: UserResponse) {

        var gs = this.GetCurrentUser();
        gs.item = gs.username;
        gs.secondItem = scenarioRec.id;
        gs.thirdItem = scenarioRec.programId;

        if (response != null && response != undefined) {
            gs.fourthItem = response.id;
        }

        var addurl = this.baseURL + 'api/neo/getscenariofeedback';
        var retval = this.http.post<FeedbackMaster>(addurl, gs)

        return retval;
    }


    // PROFILE IMAGE UPLOAD ------------------------------------------------------------------------------------------

    UploadProfileImage(imageFile: File) {
        // Must send data as Form data since it includes a file
        const formData = new FormData();

        var gs = this.GetCurrentUser();
        var token = this.GetCurrentUserToken();
        formData.append('token', token);
        formData.append("UserName", gs.username);

        if ((imageFile != null) && (imageFile != undefined)) {
            formData.append('responseFile', imageFile, imageFile.name);
        }

        var addurl = this.baseURL + 'api/neo/uploadprofileimage';
        var retval = this.http.post<UserProfile>(addurl, formData);

        return retval;
    }

    getFiles(): Observable<any> {
        return this.http.get(`${this.baseURL}/files`);
    }



    // GRAPH MANAGER ------------------------------------------------------------------------------------------
    // Older Graph Manager Functions - Deprecated

    CreateProfile(currentUser: string) {
        var gs = new GenericString();
        //gs.item = this._userService;

        var model = new LoginDto();
        // TODO - Need to get and set the user


        var addurl = this.baseURL + 'api/neo/createprofile';
        var retval = this.http.post<LoadResult[]>(addurl, model);
        return retval;
    }

    RetrieveProfile(currentUser: string) {
        var gs = new GenericString();
        var model = new LoginDto();
        // TODO - Need to get and set the user

        var addurl = this.baseURL + 'api/neo/retrieveprofile';
        var retval = this.http.post<LoadResult[]>(addurl, model);
        return retval;
    }

    LoadGraph(currentUser: string) {
        var gs = new GenericString();
        gs.item = currentUser;
        gs.token = this.GetCurrentUserToken();

        var addurl = this.baseURL + 'api/neo/loadgraph';
        var retval = this.http.post<LoadResult[]>(addurl, gs)
        return retval;
    }

    LoadMaslow(currentUser: string) {
        var gs = new GenericString();
        gs.item = currentUser;
        gs.token = this.GetCurrentUserToken();

        var addurl = this.baseURL + 'api/neo/loadmaslow';
        var retval = this.http.post<LoadResult[]>(addurl, gs)
        return retval;
    }

    LoadPresentation(currentUser: string) {
        var gs = new GenericString();
        gs.item = currentUser;
        gs.token = this.GetCurrentUserToken();

        var addurl = this.baseURL + 'api/neo/loadpresentation';
        var retval = this.http.post<LoadResult[]>(addurl, gs)
        return retval;
    }

    ClearGraph(currentUser: string) {
        var gs = new GenericString();
        gs.item = currentUser;
        gs.token = this.GetCurrentUserToken();

        var addurl = this.baseURL + 'api/neo/cleargraph';
        var retval = this.http.post<LoadResult[]>(addurl, gs)
        return retval;
    }

    InitAgent(currentUser: string) {
        var gs = new GenericString();
        gs.item = currentUser;
        gs.token = this.GetCurrentUserToken();

        var addurl = this.baseURL + 'api/neo/initagent';
        var retval = this.http.post<LoadResult[]>(addurl, gs)
        return retval;
    }

    GetAspectMap(currentUser: string) {
        var gs = new GenericString();
        gs.item = currentUser;
        gs.token = this.GetCurrentUserToken();

        var addurl = this.baseURL + 'api/neo/getaspectmap';
        var retval = this.http.post<LoadResult[]>(addurl, gs)
        return retval;
    }

    ExecuteMatrices(currentUser: string) {
        var gs = new GenericString();
        gs.item = currentUser;
        gs.token = this.GetCurrentUserToken();

        var addurl = this.baseURL + 'api/neo/executematrices';
        var retval = this.http.post<LoadResult[]>(addurl, gs)
        return retval;
    }

    GetUserEngagementVector(currentUser: string) {
        var gs = new GenericString();
        gs.item = currentUser;
        gs.token = this.GetCurrentUserToken();

        var addurl = this.baseURL + 'api/neo/userengvector';
        var retval = this.http.post<LoadResult[]>(addurl, gs)
        return retval;
    }

    GetRecommendations(currentUser: string) {
        var gs = new GenericString();
        gs.item = currentUser;
        gs.token = this.GetCurrentUserToken();

        var addurl = this.baseURL + 'api/neo/getrecommendations';
        var retval = this.http.post<LoadResult[]>(addurl, gs)
        return retval;
    }

    AcceptRecs(currentUser: string) {
        var gs = new GenericString();
        gs.item = currentUser;
        gs.token = this.GetCurrentUserToken();

        var addurl = this.baseURL + 'api/neo/acceptrecs';
        var retval = this.http.post<LoadResult[]>(addurl, gs)
        return retval;
    }

    SimMatrix(currentUser: string) {
        var gs = new GenericString();
        gs.item = currentUser;
        gs.token = this.GetCurrentUserToken();

        var addurl = this.baseURL + 'api/neo/simmatrix';
        var retval = this.http.post<LoadResult[]>(addurl, gs)

        return retval;
    }

}

export class GenericString {
    item: string;
    token: string;
    continuationToken: string;
    username: string;
    streamForUser: string;
    userType: string;
    responseFile: File;
    secondItem: string;
    thirdItem: string;
    fourthItem: string;
}

export class LoginDto {
    username: string;
    password: string;
    name: string;
    email: string;
    userType: string;
    phonenumber: string;
    twoFactorEnabled: boolean;
    emailConfirmed: boolean;
    phoneNumberConfirmed: boolean;
    emailotp: string;
    trialUserId: string;
    trialUserName: string;
    isTrialUser: boolean;
    isOnboarding: boolean;
}


export class UserDto {
    username: string;
    token: string;
    haserror: boolean;
    error: string;
    userTypes: string[];
    trialUserId: string;
    trialUserName: string;
    isTrialUser: boolean;
    isOnboarding: boolean;
}

export class MessageDto {
    token: string;
    userConnectionId: string;
    responseFile: File;
    programMessageDto: ProgramMessage;
}

export class AdminGenericDto {
    item: string;
    token: string;
    continuationToken: string;
    username: string;
    user: UserProfile;
    coach: UserProfile;
}

export class UserResponseDto {
    token: string;
    userConnectionId: string;
    userResponse: UserResponse;
}
