<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>


<!-- Wrapper -->
<div class="w-full bg-white">
<div class="flex flex-col items-center justify-center bg-white">
    <a name="home"></a>
    <!-- Header -->
    <header class="w-full bg-white fixed top-0 z-999">
        <div class="">
            <mat-toolbar class="">
                <!-- Logo -->
                <div class="flex flex-col" *ngIf="showMenu">
                    <div class="flex items-center justify-center p-4 pl-6" *ngIf="showMenu">
                        <a href="/home">
                            <img class="w-40"
                                 src="assets/images/logo/cogsolo_logo_1.png">
                        </a>
                    </div>
                </div>

                <!-- TODO  Fix for light logo on dark background -->
                <div class="flex items-center justify-center p-4 pl-6" *ngIf="!showMenu">
                    <div class="flex items-center">
                        <a href="/home">
                            <img class="w-40"
                                 src="assets/images/logo/cogsolo_logo_1.png">
                        </a>
                    </div>
                </div>



                <div class="flex flex-0 items-center justify-center h-8 pr-6 pl-2 mt-4 mb-4">
                </div>

                <!-- Horizontal navigation -->
                <div *ngIf="showMenu">
                    <nav class="block w-full max-w-screen-lg px-4 py-2 mx-auto bg-white lg:px-8 lg:py-3">
                        <div class="flex flex-row items-center justify-between mx-auto space-x-4">

                            <a mat-button href="#home"><span class="mainMenuLink">Home</span></a>
                            <a mat-button href="#whycogsolo"><span class="mainMenuLink">Why Cogsolo</span></a>
                            <a mat-button href="#howitworks"><span class="mainMenuLink">How it Works</span></a>
                            <a mat-button href="#pricing"><span class="mainMenuLink">Pricing</span></a>
                            <a mat-button href="#faqs"><span class="mainMenuLink">FAQs</span></a>
                        </div>
                    </nav>
                </div>


                <!-- Components -->
                <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">

                    <!--<div class="mt-8 text-xl font-bold tracking-tight leading-tight text-accent-600 text-center" *ngIf="showStartJoin">Setting up a quick trial for you ...</div>-->
                    <div class="text-center mr-2">
                        <button (click)="toggleLogin()" *ngIf="showLoginPanel"
                                mat-flat-button
                                [color]="'accent'"
                                class="btn btn-md text-white mb-0 px-2 items-center justify-center icon-btn">
                            <mat-icon svgIcon="mat_outline:logout" class="inline-icon"></mat-icon><span class="inline-button-text text-lg">Hide Sign In</span>
                        </button>
                        <button (click)="toggleLogin()" *ngIf="!showLoginPanel && showLoginBtn"
                                mat-flat-button
                                [color]="'accent'"
                                class="btn btn-md text-white mb-0 px-2 items-center justify-center icon-btn">
                            <mat-icon svgIcon="mat_outline:login" class="inline-icon"></mat-icon><span class="inline-button-text text-lg">  Sign In  </span>
                        </button>
                        <button (click)="logout()" *ngIf="showLogout"
                                mat-flat-button
                                [color]="'accent'"
                                class="btn btn-md text-white mb-0 px-2 items-center justify-center icon-btn">
                            <mat-icon svgIcon="mat_outline:logout" class="inline-icon"></mat-icon><span class="inline-button-text text-lg">  Sign Out  </span>
                        </button>

                    </div>
                    <div class="text-center mr-4" *ngIf="showJoinNowBtn">
                        <button (click)="JoinNow()"
                                mat-flat-button
                                [color]="'primary'"
                                class="btn btn-md text-white mb-0 px-2 items-center justify-center icon-btn">
                            <mat-icon svgIcon="mat_outline:app_registration" class="inline-icon"></mat-icon><span class="inline-button-text text-lg">Sign Up</span>
                        </button>

                    </div>

                    <!-- User -->
                    <div class="flex flex-col items-center w-full p-2" *ngIf="hasProfileImage">
                        <div class="relative w-10 h-10">

                            <img *ngIf="hasProfileImage" [src]="userprofile.imageURL" class="w-full h-full rounded-full" />

                        </div>
                        <div class="flex flex-col items-center justify-center w-full mt-2">
                            <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                                {{userprofile.name}}
                            </div>
                            <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                                {{userprofile.userName}}
                            </div>
                        </div>
                    </div>

                    <!--<user></user>-->
                </div>


            </mat-toolbar>

        </div>
    </header>

    <!-- MAIN PORTION OF THE APPLICATION -->
    <div class="flex flex-col sm:flex-row flex-auto min-w-0 position-relative mainAppPortion w-full bg-white">
        <div class="flex flex-auto">
            <router-outlet></router-outlet>
        </div>

        <div class="md:flex md:justify-end w-full h-screen sm:w-auto py-8 px-4 sm:p-12 md:p-16 md:pt-24 sm:rounded-2xl
md:rounded-none sm:shadow md:shadow-none sm:bg-card bg-gray-100 loginPanel" *ngIf="showLoginPanel">

            <auth-sign-in></auth-sign-in>

        </div>
    </div>

    <auth-starttrial></auth-starttrial>


</div>
</div>

