import { IAspect } from "./IAspect";
import { IReason } from "./IReason";
import { IScenario } from "./IScenario";
import { IScenarioRecommendation } from "./IScenarioRecommendation";
import { IUserResponse } from "./IUserResponse";
import { UserResponse } from "./UserResponse";

export class ScenarioRecommendation implements IScenarioRecommendation {
    description: string;
    entityType: string;
    scenario: IScenario;
    engTotal: number;
    reasons: IReason[];
    userName: string;
    userId: string;
    programId: string;
    roundId: string;
    status: string;
    scenarioId: string;
    roundNum: number;
    feedbackStatus: string;
    feedbackLastDate: Date;
    title: string;
    difficulty: string;
    estTimeInvestment: string;
    resourceURL: string;
    resourceThumbURL: string;
    responseFileName: string;
    responseURL: string;
    responseThumbURL: string;
    responseDate: Date;
    streamForUserName: string;
    imageURL: string;
    id: string;
    name: string;
    userResponses: UserResponse[];
    timestamp: Date;
    feedbackPoints: number;
    relatedAspects: IAspect[];
}
