import { NgModule } from '@angular/core';
import { AdminAspectMapComponent } from 'app/modules/adminprofile/adminaspectmap/adminaspectmap.component';
import { SharedModule } from '../../../shared/shared.module';
import { FuseCardModule } from '@fuse/components/card';
import { FuseAlertModule } from '@fuse/components/alert';


@NgModule({
    declarations: [
        AdminAspectMapComponent
    ],
    imports: [
        SharedModule,
        FuseCardModule,
        FuseAlertModule
    ],
    exports: [
        AdminAspectMapComponent
    ]
})
export class AdminAspectMapModule
{
}
