import { Component, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { SMService } from '../../../../services/sm.service';
import { environment } from '../../../../../environments/environment';
import { Subject } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { ScenarioRecommendation } from '../../../../models/ScenarioRecommendation';
import { Location } from '@angular/common';
import { FuseNavigationService } from '@fuse/components/navigation';
import { ActivatedRoute, Router } from '@angular/router';
import { ScenarioSideBarItem } from '../../../../models/ScenarioSideBarItem';
import { UserResponse } from '../../../../models/UserResponse';

@Component({
  selector: 'app-programscenariodetail',
  templateUrl: './programscenariodetail.component.html'
})
export class ProgramScenarioDetailComponent implements OnInit {
    baseUrl = '';
    public theURL: string;
    public thehttp: HttpClient;
    public currentUser: string = "";
    public userType: string = ""; // User, TrialUser, Admin
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = false;
    drawerPosition: 'start' | 'end' = 'end';
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public scenarioRec: ScenarioRecommendation;
    public userresponses: UserResponse[] = [];
    public currentReponse: UserResponse;
    public currentMenuItem: ScenarioSideBarItem;
    public newItemId: string;
    public priorMenuItem: ScenarioSideBarItem;
    public isRegistered: boolean = false;
    public isOnboarding: boolean = false;
    public showTrialStepper: boolean = false;
    public guidanceToggle: boolean = false;
    public notesToggle: boolean = false;

    // Sidebar Menu
    public scenarioSideBarItems: ScenarioSideBarItem[];
    public feedbackCompleted: boolean = false;
    public showSidebar: boolean = false;

    firstFormGroup = this.formBuilder.group({
        firstCtrl: [''],
    });
    secondFormGroup = this.formBuilder.group({
        secondCtrl: [''],
    });
    thirdFormGroup = this.formBuilder.group({
        thirdCtrl: [''],
    });
    fourthFormGroup = this.formBuilder.group({
        fourthCtrl: [''],
    });
    fifthFormGroup = this.formBuilder.group({
        fifthCtrl: [''],
    });

    public currentStep: number = 0;

  constructor(
    http: HttpClient,
    private formBuilder: FormBuilder,
      private smService: SMService,
      private router: Router
  )
  {
    this.baseUrl = environment.BASE_URL;
    this.theURL = this.baseUrl;
    this.thehttp = http;
  }

    goBack(): void {
        //this.location.back();
        this.router.navigate(['admintest', 'layout', 'userprofile', 'programsummarylist'])
    }

    

   ngOnInit(): void {

       ////console.log("Scenario Detail: User Type is: " + this.smService.userType);

       // Load the selected scenario recommendation
       this.LoadCurrentScenario();

    
        // Subscribe to the service to get the feedback Completed event
        if (this.smService.feedbackCompleted != null) {
            this.smService.feedbackCompleted.subscribe(result => {
                ////console.log("Feedback Completed: " + result);
                this.feedbackCompleted = result;
            });
        }


       // Set the scenario menu to the current response if there is a new one
       var q = this.smService.NewResponse.subscribe(userResponse => {

           if (userResponse != null && userResponse != undefined) {
               this.newItemId = userResponse.id;
               //console.log("New Response ID detected as: " + this.newItemId);
           }

           this.CreateSideBar();

       });

       //this.smService.isOnboarding.next(true); // For testing purposes

       // Onboarding Step Tracker
       var y = this.smService.isOnboarding.subscribe(isOnboarding => {
           if (isOnboarding) {
               this.isOnboarding = true;
               this.showTrialStepper = true;
           } else {
               this.isOnboarding = false;
               this.showTrialStepper = false;
           }
       });



       this.smService.isRegistered.subscribe(value => {
           this.CreateSideBar();
           this.isRegistered = value;
           //console.log("ProgramScenarioDetail: isRegistered: " + this.isRegistered);
       });

       // Once the user is registered show the scenario menu
       this.smService.showScenarioMenu.subscribe(response => {

           //console.log("ProgramScenarioDetail: showScenarioMenu is: " + response);

           if (this.isOnboarding) {

               //console.log("ProgramScenarioDetail: User is onboarding");

               if (this.isRegistered != true) {
                   this.showSidebar = false;
                   this.drawerOpened = false;
               } else {
                   this.showSidebar = true;
                   this.drawerOpened = true;
               }
           } else {

               //console.log("ProgramScenarioDetail: User is not onboarding");

               if (response) {
                   this.showSidebar = true;
                   this.drawerOpened = true;
               } else {
                   this.showSidebar = false;
                   this.drawerOpened = false;
               }
           }
       });


       this.DisableFormControls();
    }

    DisableFormControls() {

        this.firstFormGroup.disable();
        this.secondFormGroup.disable();
        this.thirdFormGroup.disable();
        this.fourthFormGroup.disable();
        this.fifthFormGroup.disable();

    }

    toggleGuidanceOpen() {
        // toggle the guidance bar

        this.guidanceToggle = !this.guidanceToggle;

        /*if (this.notesToggle == true) {
            this.notesToggle = false;
        }*/

        this.smService.showGuidanceBar.next(this.guidanceToggle);
        //this.smService.showNotesPanel.next(this.notesToggle);

        /*if (this.smService.showGuidanceBar.value == true) {
            this.smService.showGuidanceBar.next(false);
        } else {
            this.smService.showNotesPanel.next(false);
            this.smService.showGuidanceBar.next(true);
        }*/
        //console.log("Guidance Toggle: " + this.guidanceToggle + " Notes Toggle: " + this.notesToggle);

    }

    toggleNotesOpen() {
        // toggle the notes panel


        this.notesToggle = !this.notesToggle;

        /*if (this.guidanceToggle == true) {
            this.guidanceToggle = false;
        }*/

        //this.smService.showGuidanceBar.next(this.guidanceToggle);
        this.smService.showNotesPanel.next(this.notesToggle);


        /*if (this.smService.showNotesPanel.value == true) {
            this.smService.showNotesPanel.next(false);
        } else {
            this.smService.showGuidanceBar.next(false);
            this.smService.showNotesPanel.next(true);
        }*/
        //console.log("Guidance Toggle: " + this.guidanceToggle + " Notes Toggle: " + this.notesToggle);

    }

    LoadCurrentScenario() {
        //console.log("Scenario Detail: Check Current Scenario");

        // Redirect to scenario list if no scenario is set
        /*if (this.smService.IsTrialUser() == false) {
            if ((this.smService.CurrentScenarioRec == null) || (this.smService.CurrentScenarioRec == undefined)) {
                this.router.navigate(['admintest', 'layout', 'userprofile', 'programsummarylist']);
            }
        } else {
            if ((this.smService.CurrentScenarioRec == null) || (this.smService.CurrentScenarioRec == undefined)) {
                this.router.navigate(['starttrial']);
            }
        }*/

        if ((this.smService.CurrentScenarioRec == null) || (this.smService.CurrentScenarioRec == undefined)) {
            return;
        }

        var x = this.smService.CurrentScenarioRec.subscribe(scenrec => {
            ////console.log("In Scenario Detail scenrec = " + scenrec.title);
            this.scenarioRec = scenrec;
            this.userresponses = [];
            this.userresponses = scenrec.userResponses;

            // Create the sidebar objects representing the scenario, any tip content, user response content, and feedback content
            this.CreateSideBar();
            ////console.log("Scenario Detail: Created Sidebar");

        });

        /*var y = this.smService.userType.subscribe(response => {
            if (this.smService.IsTrialUser()) {
                //this.showSidebar = false;
                this.drawerOpened = false;
            } else {
                //this.showSidebar = true;
                this.drawerOpened = true;
            }
        });*/


    }

    private ShowMenuDate(menuItem: ScenarioSideBarItem): boolean {

        var showDate = true;

        if (menuItem != null && menuItem != undefined) {
            if (menuItem.label == "Scenario" || menuItem.label == "New Response") {
                showDate = false;
            }
        }

        return showDate;
    }


    private CreateSideBar() {

        // Initialize the sidebar items array
        this.scenarioSideBarItems = [];

        // Get the thumbnail url for the scenario rec
        var scenthumbUrl = this.scenarioRec.resourceThumbURL;
        if (scenthumbUrl == undefined || scenthumbUrl == null || scenthumbUrl == "") {
            scenthumbUrl = "assets/images/no_img_available.png";
        }


        // Create a sidebar item for the scenario
        var scenitem = {
            id: this.scenarioRec.scenarioId,
            title: 'Scenario',
            slug: 'scenario',
            icon: 'ondemand_video',
            count: 1,
            thumbnail: scenthumbUrl,
            label: 'Scenario',
            date: this.scenarioRec.timestamp,
            link: 'viewscenario',
            scenariorec: this.scenarioRec,
            response: null,
            order: 0
        };

        this.scenarioSideBarItems.push(scenitem);

        // Create a sidebar item to add a new response
        /*this.scenarioSideBarItems.push({
            id: 'practice',
            title: 'New Response',
            slug: 'practice',
            icon: 'movie_edit',
            count: 1,
            label: 'Create New',
            date: new Date(),
            link: 'viewresponse',
            scenariorec: this.scenarioRec,
            response: null,
            order: 0
        });*/

        // Create a sidebar item for any feedback on the scenario
        /*this.scenarioSideBarItems.push({
            id: 'feedback',
            title: 'Feedback',
            slug: 'feedback',
            icon: 'feedback',
            count: 1,
            label: 'View',
            date: this.scenarioRec.feedbackLastDate,
            link: 'viewfeedback',
            scenariorec: this.scenarioRec,
            response: null
        });*/


        // Set the scenario as the default initially
        //this.currentMenuItem = scenitem;

        // Create a sidebar item for any user responses to the scenario
        // Loop through all the user reponses and add a sidebar item
        if (this.userresponses != null && this.userresponses != undefined) {

            // Sort the user responses by the timestamp property in descending order
            this.userresponses.sort((a, b) => {

                if (a == null || a == undefined || b == null || b == undefined) {
                    return -1;
                }

                return <any>new Date(b.timestamp) - <any>new Date(a.timestamp)
            });

            // Create the sidebar items for the user responses.
            var i = 3;
            this.userresponses.forEach(response => {

                // Ensure a proper title for the response as Response or Practice
                this.AddNewResponseSideBarItem(response, i);
                i++;
            });
        }

        // Sort the sidebar items by the order property in ascending order
        this.scenarioSideBarItems.sort((a, b) => {
            return a.order - b.order;
        });
    }



    private SortSideBarItems() {
        this.scenarioSideBarItems.sort((a, b) => {
            return <any>new Date(b.date) - <any>new Date(a.date);
        });
    }

    private AddNewResponseSideBarItem(response: UserResponse, index: number) {
        if (response == null || response == undefined) {
            return;
        }

        var responseType = response.responseType;
        if (responseType == undefined || responseType == null) {
            responseType = "Response";
        }

        // Get the thumbnail url for the response
        var thumbUrl = response.responseFileThumb;
        if (thumbUrl == undefined || thumbUrl == null || thumbUrl == "") {
            thumbUrl = "assets/images/no_img_available.png";
        }

        var newitem = {
            id: response.id,
            title: responseType,
            slug: 'response',
            icon: 'video_camera_front',
            count: 1,
            thumbnail: thumbUrl,
            label: 'View',
            date: response.timestamp,
            link: response.responseFileName,
            scenariorec: this.scenarioRec,
            response: response,
            order: index
        };

        // Check if the items already exists before adding a new one
        var found = false;
        this.scenarioSideBarItems.forEach(item => {
            if (item.response != null && item.response != undefined) {
                if (item.response.responseFileName == newitem.response.responseFileName) {
                    found = true;

                    // Update the item with the new response data
                    // Just need to modify the date and response type
                    item.date = new Date();
                    item.title = response.responseType;
                }
            }
        });

        if (!found) {
            // add the item as the first item in the list
            //this.scenarioSideBarItems.unshift(newitem);

            // also add the item to the bottom of the list
            this.scenarioSideBarItems.push(newitem);

            // Set the current menu item to the new item
            if (newitem.id == this.newItemId) {
                this.currentMenuItem = newitem;
                //console.log("Current Menu Item Set to: " + newitem.id);
            }
        }

    }


    // Helper to set the format of the menu item
    getMenuformat(menuItem: ScenarioSideBarItem): boolean {
        var format = false;

        if (menuItem == null || menuItem == undefined) {
            return true;
        }

        if (this.currentMenuItem != null && this.currentMenuItem != undefined) {
            if (menuItem.id == this.currentMenuItem.id) {
                format = true;
            }
        }

        return format;
    }


    CreateNewResponse() {

        var responseItem = {
            id: 'practice',
            title: 'New Response',
            slug: 'practice',
            icon: 'movie_edit',
            count: 1,
            label: 'Create New',
            date: new Date(),
            link: 'viewresponse',
            scenariorec: this.scenarioRec,
            response: null,
            order: 1
        }

        this.scenarioMenu(responseItem);
    }

    scenarioMenu(menuItem: ScenarioSideBarItem): void {

        // Set the current scenario
        if (menuItem != null) {
            this.smService.CurrentScenarioRec.next(menuItem.scenariorec);
            this.currentMenuItem = menuItem;
            this.smService.menuAction.next(menuItem.title);

            // Don't reload the same menu item
            /*if (this.priorMenuItem != null && this.priorMenuItem != undefined) {
                if (this.priorMenuItem.id == this.currentMenuItem.id) {
                    return;
                } 
            }*/

            //this.priorMenuItem = this.currentMenuItem;

            // Navigate the router to the appropriate screen
            if (menuItem.title == "Scenario") {

                this.smService.VideoSource.next(menuItem.scenariorec.resourceURL);
                this.smService.VideoSourceThumb.next(menuItem.scenariorec.resourceThumbURL);
                this.smService.isResponseScreen.next(false);

                this.router.navigate(['admintest', 'layout', 'userprofile', 'programscenariodetail', 'viewscenario']);

            } else if (menuItem.title == "Response" || menuItem.title == "Practice") {

                // set the response item also
                this.smService.CurrentResponse.next(menuItem.response);
                this.smService.VideoSource.next(menuItem.response.responseFileName);
                this.smService.VideoSourceThumb.next(menuItem.response.responseFileThumb);
                this.smService.feedbackCompleted.next(true);
                this.smService.isResponseScreen.next(true);

                this.router.navigate(['admintest', 'layout', 'userprofile', 'programscenariodetail', 'viewresponse']);

            } else if (menuItem.title == "New Response") {

                //console.log("---------- New Response Activated in Scenario Menu ----------");

                this.smService.CurrentResponse.next(null);
                this.smService.VideoSource.next("");
                this.smService.VideoSourceThumb.next("");
                this.smService.feedbackCompleted.next(false);
                this.smService.isResponseScreen.next(true);

                this.router.navigate(['admintest', 'layout', 'userprofile', 'programscenariodetail', 'viewresponse'])

            } else if (menuItem.title == "Feedback") {

                this.smService.CurrentResponse.next(menuItem.response);
                this.smService.VideoSource.next("");

                this.router.navigate(['admintest', 'layout', 'userprofile', 'programscenariodetail', 'viewfeedback']);
            }


        }

    }
 }

