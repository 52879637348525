import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { SMService } from '../../../services/sm.service';
import { ILoadResult } from '../../../models/ILoadResult';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FuseAlertType } from '@fuse/components/alert';
import { fuseAnimations } from '@fuse/animations';
import { Aspect } from '../../../models/Aspect';
import { Scenario } from '../../../models/Scenario';
import { Edge } from '../../../models/Edge';
import { v4 as uuidv4 } from 'uuid';

export enum SelectType {
    single,
    multiple
}

@Component({
    selector: 'app-adminscenariomap',
    templateUrl: './adminscenariomap.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    styleUrls: ['./adminscenariomap.component.css']
})
export class AdminScenarioMapComponent implements OnInit {
    baseUrl = '';
    public theURL: string;
    public thehttp: HttpClient;
    public loadResults: ILoadResult[];
    public currentResult: string;
    public currentUser: string = "";
    public smService: SMService;
    public assignmentForm: FormGroup;
    public hasRelationships: boolean = false;
    public PageTitle: string = "Scenario Relationships Admin";

    public scenarios: Scenario[] = []; // Initial set of scenarios
    public aspects2: Aspect[] = []; // The set of aspects related to a scenario from the first set
    public edges: Edge[] = []; // The set of edges between the scenarios and aspects
    public edgesToUpdate: Edge[] = []; // The set of edges to update

    public filteredscenarios: Scenario[];
    public filteredscenariosLength: number = 0;
    public filteredaspects: Aspect[];

    public selectedItem: Scenario;
    public selectedItem2: Aspect;
    public showItemDetail: boolean = false;
    public showItem2: boolean = false;
    public showEdgeList: boolean = false;
    public selectedEdge: Edge;

    visibleTab: string = "";

    displayedColumns: string[] = [
        "name",
        "id",
        "description",
        "resourceURL",
        "numRelationships",
        "details"
    ];

    edgedisplayedColumns: string[] = [
        "startnodename",
        "endnodename",
        "endnodeid",
        "type",
        "length",
        "weight",
        "actions"
    ];

    aspectdisplayedColumns: string[] = [
        "name",
        "id",
        "engStart",
        "engEarly",
        "aspectType",
        "details"
    ];

    selectType = [
        { text: "Single", value: SelectType.single },
        { text: "Multiple", value: SelectType.multiple }
    ];

    selectFilterType = [
        { text: "Scenarios", value: "Scenarios" },
        { text: "Tips", value: "Tips" },
        { text: "All", value: "All" },
    ];

    selectAspectFilterType = [
        { text: "Model", value: "Model" },
        { text: "Aspect Group", value: "Aspect Group" },
        { text: "Aspect", value: "Aspect" },
        { text: "All", value: "All" },
    ];


    dataSource = new MatTableDataSource<Scenario>();
    selection = new SelectionModel<Scenario>(true, []);
    dataSource2 = new MatTableDataSource<Aspect>();
    selection2 = new SelectionModel<Aspect>(true, []);

    edgedataSource = new MatTableDataSource<Edge>();
    edgeselection = new SelectionModel<Edge>(true, []);

    displayType = SelectType.single;
    filterType = "Scenarios";
    aspectfilterType = "Aspect";

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: 'Successfully updated the scenario-aspect relationships.'
    };
    showAlert: boolean = false;


    constructor(
        http: HttpClient,
        private fb: FormBuilder,
        sms: SMService
    ) {
        this.baseUrl = environment.BASE_URL;
        this.theURL = this.baseUrl;
        this.thehttp = http;
        this.smService = sms;
    }

    onChange(typeValue: number) {
        this.displayType = typeValue;
        this.selection.clear();
    }

    onChange2(typeValue: number) {
        this.displayType = typeValue;
        this.selection2.clear();
    }

    onFilterChange(typeValue: string) {
        this.filterType = typeValue;
        this.FilterScenarios();
    }

    onAspectFilterChange(typeValue: string) {
        this.aspectfilterType = typeValue;
        this.FilterAspects();
    }

    onEdgeChange(typeValue: number) {
        this.displayType = typeValue;
        this.edgeselection.clear();
    }

    selectHandler(row: Scenario) {
        if (this.displayType == SelectType.single) {
            if (!this.selection.isSelected(row)) {
                this.selection.clear();
            }
        }
        this.selection.toggle(row);
    }

    selectHandler2(row: Aspect) {
        if (this.displayType == SelectType.single) {
            if (!this.selection2.isSelected(row)) {
                this.selection2.clear();
            }
        }
        this.selection2.toggle(row);
    }

    edgeselectHandler(row: Edge) {
        if (this.displayType == SelectType.single) {
            if (!this.edgeselection.isSelected(row)) {
                this.edgeselection.clear();
            }
        }
        this.edgeselection.toggle(row);
    }


    ngOnInit(): void {
        // Get the List of Scenarios in the Graph Database
        this.smService.GetAdminScenarios().subscribe(result => {

            this.scenarios = result.scenarios;
            this.FilterScenarios();

        }, error => console.error(error));
    }

    FilterScenarios() {

        // Filter the list of scenarios based on the filterType
        if (this.filterType == "Scenarios") {
            this.filteredscenarios = this.scenarios.filter(x => x.format == "scenario");
        }
        else if (this.filterType == "Tips") {
            this.filteredscenarios = this.scenarios.filter(x => (x.format == "tip" || x.format == "" || x.format == undefined));
        } else {
            this.filteredscenarios = this.scenarios;
        }

        this.filteredscenariosLength = this.filteredscenarios.length;
        this.dataSource = new MatTableDataSource<Scenario>(this.filteredscenarios);

    }

    GetSelectedItem() {

        if (this.selectedItem == null || this.selectedItem == undefined) {
            return "";
        } else {
            return this.selectedItem.name;
        }
    }


    CloseItem() {
        this.showItemDetail = false;
        this.showEdgeList = false;
        this.showItem2 = false;
        this.selectedItem = null;
        this.edges = [];
        this.edgedataSource = new MatTableDataSource<Edge>(this.edges);
    }

    SelectFirstItem(item) {
        console.log("Item selected is: " + item.name);
        this.selectedItem = item;
        this.showAlert = false;
        this.edges = null;
        this.showItemDetail = false;
        this.showEdgeList = false;
        this.hasRelationships = false;

        // Load the Relationships for the Selected Item
        this.smService.GetRelatedAspectsForScenario(this.selectedItem.id).subscribe(result => {

            this.edges = result.edges;
            this.edgedataSource = new MatTableDataSource<Edge>(this.edges);

            // if edges are not empty, then we have relationships
            if (this.edges.length > 0) {
                this.hasRelationships = true;
                this.showEdgeList = true;

                // Update the form
                this.UpdateForm();
            }

        }, error => console.error(error));

        this.showItemDetail = true;
    }

    UpdateForm() {
        // Create the form for the relationship weightings
        this.assignmentForm = this.fb.group({
            formedges: this.fb.array([])
        });

        // Maps all the edges into a formGroup defined in the edge model
        const fgs = this.edges.map(Edge.asFormGroup);
        var edgesarray = new FormArray(fgs);

        // Set the form array to the form
        this.assignmentForm.setControl('formedges', edgesarray);
    }


    get formedges(): FormArray {
        return this.assignmentForm.get('formedges') as FormArray;
    }

    SelectSecondItem(item) {
        var newedge = new Edge();
        newedge.startNodeName = this.selectedItem.name;
        newedge.startId = this.selectedItem.id;
        newedge.startNode = this.selectedItem.name;
        newedge.startNodeId = this.selectedItem.id;
        newedge.endNode = item.name;
        newedge.endNodeId = item.id;
        newedge.endId = item.id;
        newedge.endNodeName = item.name;
        newedge.type = "Supports";
        newedge.length = 1;
        newedge.weight = 1.0;
        newedge.id = uuidv4(); // Of format '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
        this.edges.push(newedge);

        this.UpdateForm();
        this.showItem2 = false;

        this.hasRelationships = true;
        this.showEdgeList = true;

    }

    AddNewEdge() {

        // Get the List of Aspects in the Graph Database
        this.smService.GetAdminAspects().subscribe(result => {

            this.aspects2 = result.aspects;

            // loop through the aspects2 and remove any that are already in the edges
            for (var i = 0; i < this.aspects2.length; i++) {

                for (var j = 0; j < this.edges.length; j++) {
                    if (this.aspects2[i].id == this.edges[j].endId) {
                        this.aspects2.splice(i, 1);
                    }
                }
            }

            this.FilterAspects();


        }, error => console.error(error));

        this.showItem2 = true;

    }

    FilterAspects() {

        // Filter the list of aspects based on the filterType
        if (this.aspectfilterType == "Model") {
            this.filteredaspects = this.aspects2.filter(x => x.aspectType == "model");
        }
        else if (this.aspectfilterType == "Aspect Group") {
            this.filteredaspects = this.aspects2.filter(x => x.aspectType == "aspectgroup");
        }
        else if (this.aspectfilterType == "Aspect") {
            this.filteredaspects = this.aspects2.filter(x => (x.aspectType == "Aspect") || (x.aspectType == "aspect"));
        } else {
            this.filteredaspects = this.aspects2;
        }

        this.dataSource2 = new MatTableDataSource<Aspect>(this.filteredaspects);

    }


    DeleteEdge(index: number) {
        this.edges.splice(index, 1);
        this.UpdateForm();
    }

    SaveEdges() {
        // Get all the edges from the form and update the edges array
        var numedges = this.assignmentForm.value.formedges.length;
        for (var i = 0; i < numedges; i++) {
            var edge = new Edge();

            // set the edge values from the form
            edge.startNodeName = this.assignmentForm.value.formedges[i].startNodeName;
            edge.startId = this.assignmentForm.value.formedges[i].startId;
            edge.startNode = this.assignmentForm.value.formedges[i].startNode;
            edge.startNodeId = edge.startId;
            edge.endNode = this.assignmentForm.value.formedges[i].endNode;
            edge.endNodeId = edge.endId;
            edge.endId = this.assignmentForm.value.formedges[i].endId;
            edge.endNodeName = this.assignmentForm.value.formedges[i].endNodeName;
            edge.type = this.assignmentForm.value.formedges[i].type;
            edge.length = this.assignmentForm.value.formedges[i].length;
            edge.weight = this.assignmentForm.value.formedges[i].weight;
            edge.id = this.assignmentForm.value.formedges[i].id;
            edge.startNodePos = 0;
            edge.endNodePos = 0;
            this.edgesToUpdate.push(edge);
        }

        // Send the edges to the server to update the graph
        this.smService.UpdateScenarioEdges(this.edgesToUpdate).subscribe(result => {
            this.showAlert = true;

            // reset
            this.edgesToUpdate = [];

        }, error => console.error(error));

        // Update the item in the list
        this.selectedItem.numRelationships = this.edges.length;

        // Update the filteredscenarios list with the updated item
        for (var i = 0; i < this.filteredscenarios.length; i++) {
            if (this.filteredscenarios[i].id == this.selectedItem.id) {
                this.filteredscenarios[i] = this.selectedItem;
            }
        }

        this.dataSource = new MatTableDataSource<Scenario>(this.filteredscenarios);
        this.selectedItem = null;

    }
}

