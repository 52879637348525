/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'skills',
        title: 'My Programs',
        //subtitle: 'Your skill programs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [

        ]
    },
    {
        id: 'config',
        title: 'Configuration',
        //subtitle: 'Control how Cogsolo works',
        type: 'group',
        icon: 'heroicons_outline:cog',
        children: [

        ]
    },
];
export const compactNavigation: FuseNavigationItem[] = [

];
export const futuristicNavigation: FuseNavigationItem[] = [

];
export const horizontalNavigation: FuseNavigationItem[] = [

];
