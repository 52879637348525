import { Input, OnInit } from '@angular/core';
import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ProgramMessage } from '../../../../../models/ProgramMessage';


@Component({
    selector: 'app-messagevideo-player',
    templateUrl: './messagevideo.component.html',
    styleUrls: ['./messagevideo.component.css', './controls.css', 'amsterdam-acid-blue.css'],
})


export class MessageVideoComponent implements OnInit {

    @Input() msg: ProgramMessage;
    public source: string = "";
    public video: any = {
        src: "",
        type: 'video/mp4'
    };

    constructor() { }

    ngOnInit(): void {

        //console.log("Scenario is: " + this.msg.scenarioId);

        if (this.msg.scenarioId == undefined || this.msg.scenarioId == 'undefined') {
            // For user or coach video messages, the video URL is the response file name
            //console.log("Message Response File Name is: " + this.msg.responseFileName);
            this.source = this.msg.responseFileName;
            //console.log("Source is: " + this.source);

        } else {

            // For scenarios, the resourceURL is the video URL
            //console.log("Message Resource URL is: " + this.msg.resourceURL);
            this.source = this.msg.resourceURL;
            //console.log("Source is: " + this.source);
        }

        this.video = {
            src: this.source,
            type: 'video/mp4'
        }

        //console.log("Video src set to: " + this.video.src);
        
    }


    onPlayerReady(source) {

        //console.log("onPlayerReady Called");

        //console.log("Video src set to: " + this.video.src);

        // TODO Load the player
        /*this.video = {
            src: this.msg.resourceURL,
            type: 'video/mp4'
        }*/
    }

}

        
