import { NgModule } from '@angular/core';
import { CoachProfileComponent } from 'app/modules/coachprofile/coachprofile.component';
import { SharedModule } from '../../shared/shared.module';
import { ProgramStreamModule } from '../userprofile/programstream/programstream.module';


@NgModule({
    declarations: [
        CoachProfileComponent
    ],
    imports: [
        SharedModule,
        ProgramStreamModule
    ],
    exports: [
        CoachProfileComponent
    ]
})
export class CoachProfileModule
{
}
