import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { SMService } from '../../../services/sm.service';
import { ILoadResult } from '../../../models/ILoadResult';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FuseAlertType } from '@fuse/components/alert';
import { fuseAnimations } from '@fuse/animations';
import { Edge } from '../../../models/Edge';
import { v4 as uuidv4 } from 'uuid';
import { Curriculum } from '../../../models/Curriculum';
import { Aspect } from '../../../models/Aspect';
import { Scenario } from '../../../models/Scenario';

export enum SelectType {
    single,
    multiple
}

@Component({
  selector: 'app-admincurriculum',
    templateUrl: './admincurriculum.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AdminCurriculumComponent implements OnInit {
    baseUrl = '';
    public theURL: string;
    public thehttp: HttpClient;
    public loadResults: ILoadResult[];
    public currentResult: string;
    public currentUser: string = "";
    public smService: SMService;
    public curriculumForm: FormGroup;
    public PageTitle: string = "Curriculum Admin";
    public curriculums: Curriculum[];
    public selectedItem: Curriculum;
    public showForm: boolean = false;
    public aspectsControl = new FormControl();
    public aspectgroupsControl = new FormControl();
    public scenariosControl = new FormControl();
    public selectedAspectGroup: string;
    public isEditing: boolean = false;

    visibleTab: string = "";

    displayedColumns: string[] = [
        "curriculumNum",
        "phaseName",
        "phaseNum",
        "aspectGroupName",
        "aspectGroupNum",
        "aspectName",
        "scenarioTitle",
        "scenAspRelType",
        "scenAspRelLength",
        "scenAspRelWeight",
        "practice",
        "level",
        "priority",
        "isRecommendable",
        "edit"
    ];

    dataSource = new MatTableDataSource<Curriculum>();
    selection = new SelectionModel<Curriculum>(true, []);

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: 'Successfully updated the curriculum.'
    };
    showAlert: boolean = false;

    public allaspects: Aspect[];
    public aspects: Aspect[];
    public aspectgroups: Aspect[];
    public scenarios: Scenario[];


    constructor(
        http: HttpClient,
        private formBuilder: FormBuilder,
        sms: SMService
        )
    {
        this.baseUrl = environment.BASE_URL;
        this.theURL = this.baseUrl;
        this.thehttp = http;
        this.smService = sms;
    }

    ngOnInit(): void {

        this.GetCurriculumItems();

        this.GetAspectGroups();

        this.GetScenarios();

        this.curriculumForm = this.formBuilder.group({
            curriculumnum: [""],
            phasename: ["First Phase"],
            phasenum: ["1"],
            level: ["1"],
            priority: ["1"],
            isRecommendable: ["Yes"],
            practice: ["Yes"],
            aspects: this.aspectsControl,
            aspectgroups: this.aspectgroupsControl,
            scenarios: this.scenariosControl,
            aspectGroupNum: [""],
            scenAspRelType: ["Supports"],
            scenAspRelLength: ["1"],
            scenAspRelWeight: ["1"]
        });

    }

    onSelectAspectGroup(ob) {

        var aspectgroup = this.aspectgroups.find(a => a.id == ob.value);

        if (aspectgroup != undefined) {
            //console.log("Getting Aspects for Group: " + aspectgroup.name);
            if (!this.isEditing) {
                this.GetAspects(aspectgroup.id)
            }
        }
    }

    GetAspectGroups() {

        // Get the List of Aspects in the Graph Database
        this.smService.GetAdminAspects().subscribe(result => {

            var aspectset = result.aspects;

            // Sort aspects into aspects and aspect groups based on the type
            this.aspectgroups = aspectset.filter(a => a.aspectType == "aspectgroup" || a.aspectType == "AspectGroup");
            this.allaspects = aspectset.filter(a => a.aspectType == "aspect" || a.aspectType == "Aspect");

            // Sort them alphabetically
            this.aspectgroups.sort((a, b) => a.name.localeCompare(b.name));
            this.allaspects.sort((a, b) => a.name.localeCompare(b.name));

        }, error => console.error(error));

    }


    GetAspects(aspectGroupId:string) {

        // Get the List of Aspects in the Graph Database
        //console.log("Getting Aspects for Group: " + aspectGroupId);
        this.smService.GetRelatedAspects(aspectGroupId).subscribe(result => {

            this.aspects = result.aspects;

            // Sort aspects into aspects and aspect groups based on the type
            this.aspects = this.aspects.filter(a => a.aspectType == "aspect" || a.aspectType == "Aspect");

            // Sort them alphabetically
            this.aspects.sort((a, b) => a.name.localeCompare(b.name));

            if (this.isEditing) {
                this.curriculumForm.get('aspects').setValue(this.selectedItem.aspectId);
            }


        }, error => console.error(error));

    }

    GetScenarios() {
        // Get the List of Scenarios in the Graph Database
        this.smService.GetAdminScenarios().subscribe(result => {

            this.scenarios = result.scenarios;

            // Filter the list of scenarios to only get scenarios and not tips
            this.scenarios = this.scenarios.filter(x => x.format == "Scenario" || x.format == "scenario");

            // Sort them alphabetically
            this.scenarios.sort((a, b) => a.title.localeCompare(b.title));

        }, error => console.error(error));
    }


    GetCurriculumItems() {
        // Get all the Curriculum items for the given program
        this.smService.GetProgramCurriculum().subscribe(result => {

            this.curriculums = result.curriculums;

            // sort by curriculum number
            this.curriculums.sort((a, b) => a.curriculumNum - b.curriculumNum);

            this.dataSource = new MatTableDataSource<Curriculum>(this.curriculums);

        }, error => console.error(error));

    }


    AddNewItem() {

        // Reset the curriculum Form
        this.curriculumForm.reset();
        this.selectedItem = undefined;
        this.showForm = true;
        this.isEditing = false;
        this.showAlert = false;

        // Set the form defaults
        this.curriculumForm.get('phasename').setValue("First Phase");
        this.curriculumForm.get('phasenum').setValue("1");
        this.curriculumForm.get('level').setValue("1");
        this.curriculumForm.get('priority').setValue("1");
        this.curriculumForm.get('isRecommendable').setValue("true");
        this.curriculumForm.get('practice').setValue("true");
        this.curriculumForm.get('scenAspRelType').setValue("Supports");
        this.curriculumForm.get('scenAspRelLength').setValue("1");
        this.curriculumForm.get('scenAspRelWeight').setValue("1");
    }


    AddCurriculum() {
        var curriculum = new Curriculum();

        // Set the values from the form
        curriculum.curriculumNum = this.curriculumForm.get('curriculumnum').value;
        curriculum.phaseName = this.curriculumForm.get('phasename').value;
        curriculum.phaseNum = this.curriculumForm.get('phasenum').value;
        curriculum.level = this.curriculumForm.get('level').value;
        curriculum.priority = this.curriculumForm.get('priority').value;
        curriculum.isRecommendable = this.curriculumForm.get('isRecommendable').value;
        curriculum.practice = this.curriculumForm.get('practice').value;
        curriculum.aspectGroupNum = this.curriculumForm.get('aspectGroupNum').value;
        curriculum.scenAspRelType = this.curriculumForm.get('scenAspRelType').value;
        curriculum.scenAspRelLength = this.curriculumForm.get('scenAspRelLength').value;
        curriculum.scenAspRelWeight = this.curriculumForm.get('scenAspRelWeight').value;

        // Get other form values
        curriculum.aspectId = this.curriculumForm.get('aspects').value;
        curriculum.aspectGroupId = this.curriculumForm.get('aspectgroups').value;
        curriculum.scenarioId = this.curriculumForm.get('scenarios').value;

        // Get the matching aspect for the selected aspectId
        var aspect = this.aspects.find(a => a.id == curriculum.aspectId);
        if (aspect != undefined) {
            curriculum.aspectName = aspect.name;
        }

        // Get the matching aspect group for the selected aspectGroupId
        var aspectGroup = this.aspectgroups.find(a => a.id == curriculum.aspectGroupId);
        if (aspectGroup != undefined) {
            curriculum.aspectGroupName = aspectGroup.name;
        }

        // Get the matching scenario for the selected scenarioId
        var scenario = this.scenarios.find(a => a.id == curriculum.scenarioId);
        if (scenario != undefined) {
            curriculum.scenarioTitle = scenario.title;
        }

        if (this.selectedItem != undefined) {
            curriculum.id = this.selectedItem.id;

            // Remove the selected item from the curriculum list
            var index = this.curriculums.indexOf(this.selectedItem);
            if (index > -1) {
                this.curriculums.splice(index, 1);
            }
        }

        // Send the curriculum to the server to add
        this.smService.AddCurriculum(curriculum).subscribe(result => {
            this.showAlert = true;

            curriculum = result;
            this.curriculums.push(curriculum);

            // Sort the curriculums by curriculumNum
            this.curriculums.sort((a, b) => a.curriculumNum - b.curriculumNum);

            // Load the data source
            this.dataSource = new MatTableDataSource<Curriculum>(this.curriculums);

            this.showForm = false;

        }, error => console.error(error));

    }

    EditCurriculum(curriculum: Curriculum) {
        this.isEditing = true;

        // Need the full set of aspects unfiltered by aspect group
        this.aspects = this.allaspects;

        this.selectedItem = curriculum;
        this.showForm = true;

        // Set the values from the curriculum to the form fields
        this.curriculumForm.get('phasename').setValue(curriculum.phaseName);
        this.curriculumForm.get('phasenum').setValue(curriculum.phaseNum);
        this.curriculumForm.get('level').setValue(curriculum.level);
        this.curriculumForm.get('priority').setValue(curriculum.priority);
        this.curriculumForm.get('isRecommendable').setValue(curriculum.isRecommendable);
        this.curriculumForm.get('practice').setValue(curriculum.practice);
        this.curriculumForm.get('aspectGroupNum').setValue(curriculum.aspectGroupNum);
        this.curriculumForm.get('scenAspRelType').setValue(curriculum.scenAspRelType);
        this.curriculumForm.get('scenAspRelLength').setValue(curriculum.scenAspRelLength);
        this.curriculumForm.get('scenAspRelWeight').setValue(curriculum.scenAspRelWeight);
        this.curriculumForm.get('aspects').setValue(curriculum.aspectId);
        this.curriculumForm.get('aspectgroups').setValue(curriculum.aspectGroupId);
        this.curriculumForm.get('scenarios').setValue(curriculum.scenarioId);
        this.curriculumForm.get('curriculumnum').setValue(curriculum.curriculumNum);

    }

}

