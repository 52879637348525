<div style="text-align:center">
    <div class="flex flex-auto w-full items-center">
        <div class="w-full">
            <div class="flex flex-col p-6 bg-card rounded-2xl overflow-hidden mr-3 ml-3 items-center" *ngIf="showCapture">
                <div class="flex justify-center">
                    <div class="text-2xl font-semibold tracking-tight leading-6 truncate mb-6">Camera</div>
                </div>
                <div class="flex items-center"><video class="video" #liveVideo autoplay [muted]="true" controls></video></div>
                <div class="flex flex-row items-center h-15">
                    <div class="flex flex-col items-center">
                        <button mat-flat-button color="primary" *ngIf="!isRecording" (click)="startVideoRecording()">
                            <mat-icon>videocam</mat-icon><span class="ml-2">Start Recording</span>
                        </button>
                    </div>
                    <div class="flex flex-col items-center">
                        <button mat-flat-button color="warn" *ngIf="isRecording" (click)="stopVideoRecording()">
                            <mat-icon>videocam_off</mat-icon><span class="ml-2">Stop Recording</span>
                        </button>
                    </div>

                </div>
                <div class="text-sm mt-2">Be sure to speak clearly and set your microphone volume properly.</div>

            </div>

            <div class="flex-auto p-6 bg-card rounded-2xl overflow-hidden mr-3 ml-3" >
                <div class="flex justify-center" *ngIf="!showCapture">
                    <div class="text-2xl font-semibold tracking-tight leading-6 truncate mb-6">Recording</div>
                </div>
                <div class="flex items-center">
                    <video [ngClass]="hasRecording == true ? 'video' : 'videoHolder'"
                           controls #recordedVideo id="recordedVideo">
                    </video>
                </div>

                <div class="flex flex-row items-center h-15" *ngIf="hasRecording && !showCapture">
                    <div class="flex flex-col items-center">
                        <button mat-flat-button color="primary" (click)="uploadRecording()" *ngIf="allowSaving" class="ml-2">
                            <mat-icon>save_alt</mat-icon><span class="ml-2">Save</span>
                        </button>
                    </div>
                    <div class="flex flex-col items-center">
                        <button mat-flat-button color="warn" (click)="cancelRecorder()" class="ml-2">
                            <mat-icon>cancel_presentation</mat-icon><span class="ml-2">Cancel Recording</span>
                        </button>
                    </div>
                    <div class="flex flex-col items-center">
                        <button mat-flat-button color="accent" (click)="download()" class="ml-2">
                            <mat-icon svgIcon="mat_outline:file_download"></mat-icon><span class="ml-2">Download</span>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>

