import { IProgramMessage } from './IProgramMessage';
import { IProgramStream } from './IProgramStream';

export class ProgramStream implements IProgramStream {
    id: string;
    name: string;
    partitionKey: string;
    rowKey: string;
    userId: string;
    userName: string;
    entityType: string;
    messages: IProgramMessage[];
    continuationToken: string;

    constructor() {
        this.id = "";
        this.name = ""; 
    }

}
