<ng-container *ngIf="msg.messageType == 'General' || msg.messageType == 'ProgramMessage' || msg.messageType == 'Feedback'">

    <div *ngIf="msg.isNew == true" class="bg-card flex flex-col rounded-md shadow-sm messageBox mt-2 mb-2 mr-4 ml-4 p-2">
        <div class="flex items-center">
            <img class="w-8 h-8 rounded-full mr-2"
                 src="assets/images/avatars/cogsolo__mobile_logo_1.png"
                 alt="Card cover image">
            <div class="flex flex-col">
                <span class="font-semibold leading-none">{{msg.authorName | titlecase}}</span>
                <span class="text-sm text-secondary leading-none mt-1">{{msg.messageDate | date}}</span>
            </div>

        </div>
        <div class="">
            <fuse-alert class=""
                        [appearance]="'outline'"
                        [showIcon]="false"
                        [type]="success">
                <span fuseAlertTitle></span>
                {{msg.messageText}}
            </fuse-alert>
        </div>
    </div>

    <div *ngIf="msg.isNew == false" class="bg-card flex flex-col rounded-lg shadow-sm messageBox mt-2 mb-2 mr-4 ml-4 p-2">
        <div class="flex items-center">
            <img class="w-8 h-8 rounded-full mr-2"
                 src="assets/images/avatars/cogsolo__mobile_logo_1.png"
                 alt="Card cover image">
            <div class="flex flex-col">
                <span class="text-md font-semibold leading-none">{{msg.authorName | titlecase}}</span>
                <span class="text-sm text-secondary leading-none mt-1">{{msg.messageDate | date : "short"}}</span>
            </div>

        </div>
        <div class="">
            <fuse-alert class=""
                        [appearance]="'outline'"
                        [showIcon]="false"
                        [type]="success">
                <span fuseAlertTitle></span>
                {{msg.messageText}}
            </fuse-alert>
        </div>
    </div>


</ng-container>
