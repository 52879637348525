import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AuthSignInComponent } from 'app/modules/auth/sign-in/sign-in.component';

const authSignInRoutes: Route[] = [
    {
        path     : '',
        component: AuthSignInComponent
    }
];
@NgModule({
    imports: [RouterModule.forChild(authSignInRoutes)],
    exports: [RouterModule]
})
export class AuthSignInRoutingModule { }
