<div class="text-xl md:text-xl font-semibold tracking-tight leading-7 md:leading-snug truncate">{{PageTitle}}</div>

<!-- Curriculum Form -->
<div class="flex flex-col w-full mt-2 mr-1 rounded-lg">

    <form id="curriculumForm" [formGroup]="curriculumForm" class="w-full">

        <div class="flex items-center justify-left mt-8 mb-8">
            <button class="px-6 ml-3"
                    mat-flat-button
                    type="button"
                    [color]="'primary'"
                    (click)="AddNewItem()">
                <mat-icon>add</mat-icon><span class="ml-2">Add New Curriculum</span>
            </button>

            <button class="px-6 ml-3"
                    mat-flat-button
                    type="button"
                    [color]="'primary'"
                    (click)="AddCurriculum()">
                <mat-icon>cloud_upload</mat-icon><span class="ml-2">Save Curriculum</span>
            </button>

            <!-- Alerts -->
            <fuse-alert class="ml-4"
                        *ngIf="showAlert"
                        [appearance]="'outline'"
                        [showIcon]="true"
                        [type]="alert.type">
                <img src="../../../../../../assets/images/avatars/cogsolo__mobile_logo_1.png" mr-4 class="cogsologuy" fuseAlertIcon rounded-full />
                {{alert.message}}
            </fuse-alert>

        </div>

        <mat-divider></mat-divider>

        <div class="flex flex-col" *ngIf="showForm">

            <div class="flex-auto grid grid-cols-6">

                <div class="col-span-3 sm:col-span-3 flex flex-col items-left justify-top">
                    <div class="p-2 w-full">

                        <!-- Curriculum Num -->
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <mat-form-field class="fuse-mat-dense flex-auto">
                                <mat-label class="text-lg font-semibold mt-5">Curriculum Num</mat-label>
                                <input id="'curriculumnum'"
                                       matInput
                                       type="number"
                                       [formControlName]="'curriculumnum'">
                            </mat-form-field>
                        </div>

                        <!-- Aspect Group Num -->
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <mat-form-field class="fuse-mat-dense flex-auto">
                                <mat-label class="text-lg font-semibold mt-5">Aspect Group #</mat-label>
                                <input id="'aspectGroupNum'"
                                       matInput
                                       type="number"
                                       [formControlName]="'aspectGroupNum'">
                            </mat-form-field>
                        </div>

                        <!-- Phase Name -->
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <mat-form-field class="fuse-mat-dense flex-auto">
                                <mat-label class="text-lg font-semibold mt-5">Phase Name</mat-label>
                                <input id="'phasename'"
                                       matInput
                                       [formControlName]="'phasename'">
                            </mat-form-field>
                        </div>


                        <!-- Phase Num -->
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <mat-form-field class="fuse-mat-dense flex-auto">
                                <mat-label class="text-lg font-semibold mt-5">Phase Num</mat-label>
                                <input id="'phasenum'"
                                       matInput
                                       type="number"
                                       [formControlName]="'phasenum'">
                            </mat-form-field>
                        </div>

                        <!-- Level -->
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <mat-form-field class="fuse-mat-dense flex-auto">
                                <mat-label class="text-lg font-semibold mt-5">Level</mat-label>
                                <input id="'level'"
                                       matInput
                                       type="number"
                                       [formControlName]="'level'">
                            </mat-form-field>
                        </div>

                        <!-- Priority -->
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <mat-form-field class="fuse-mat-dense flex-auto">
                                <mat-label class="text-lg font-semibold mt-5">Priority</mat-label>
                                <input id="'priority'"
                                       matInput
                                       type="number"
                                       [formControlName]="'priority'">
                            </mat-form-field>
                        </div>


                        <!-- IsRecommendable -->
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <mat-form-field class="fuse-mat-dense flex-auto">
                                <mat-label class="text-lg font-semibold mt-5">Is Recommendable</mat-label>
                                <mat-select formControlName="isRecommendable">
                                    <mat-option value="true">true</mat-option>
                                    <mat-option value="false">false</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- Practice -->
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <mat-form-field class="fuse-mat-dense flex-auto">
                                <mat-label class="text-lg font-semibold mt-5">Needs Practice</mat-label>
                                <mat-select formControlName="practice">
                                    <mat-option value="true">true</mat-option>
                                    <mat-option value="false">false</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>
                </div>
                <div class="col-span-3 sm:col-span-3 flex flex-col items-left justify-top">
                    <div class="p-2 w-full">

                        <!-- Aspect Groups -->
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <mat-form-field class="fuse-mat-dense flex-auto">
                                <mat-label class="text-lg font-semibold mt-5">Aspect Group</mat-label>
                                <mat-select #aspectgroupsList [formControl]="aspectgroupsControl" [multiple]="false" (selectionChange)="onSelectAspectGroup($event)">
                                    <mat-option value="{{aspectgroup.id}}" *ngFor="let aspectgroup of aspectgroups" matInput>
                                        {{aspectgroup.name}} - {{aspectgroup.id}}
                                    </mat-option>
                                </mat-select>

                            </mat-form-field>
                        </div>

                        <!-- Aspects -->
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <mat-form-field class="fuse-mat-dense flex-auto">
                                <mat-label class="text-lg font-semibold mt-5">Aspect</mat-label>
                                <mat-select #aspectsList [formControl]="aspectsControl" [multiple]="false">
                                    <mat-option value="{{aspect.id}}" *ngFor="let aspect of aspects" matInput>
                                        {{aspect.name}} - {{aspect.id}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- Scenario -->
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <mat-form-field class="fuse-mat-dense flex-auto">
                                <mat-label class="text-lg font-semibold mt-5">Scenario</mat-label>
                                <mat-select #scenariosList [formControl]="scenariosControl" [multiple]="false">
                                    <mat-option value="{{scenario.id}}" *ngFor="let scenario of scenarios" matInput>
                                        {{scenario.name}} - {{scenario.id}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- Scen Aspect Rel Type -->
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <mat-form-field class="fuse-mat-dense flex-auto">
                                <mat-label class="text-lg font-semibold mt-5">Scenario Aspect Rel Type</mat-label>
                                <mat-select formControlName="scenAspRelType">
                                    <mat-option value="Supports">Supports</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- Scen Aspect Rel Length -->
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <mat-form-field class="fuse-mat-dense flex-auto">
                                <mat-label class="text-lg font-semibold mt-5">Rel Length</mat-label>
                                <input id="'scenAspRelLength'"
                                       matInput
                                       type="number"
                                       [formControlName]="'scenAspRelLength'">
                            </mat-form-field>
                        </div>

                        <!-- Scen Aspect Rel Weight -->
                        <div class="flex flex-col gt-xs:flex-row gt-xs:items-baseline">
                            <mat-form-field class="fuse-mat-dense flex-auto">
                                <mat-label class="text-lg font-semibold mt-5">Rel Weight</mat-label>
                                <input id="'scenAspRelWeight'"
                                       matInput
                                       type="number"
                                       [formControlName]="'scenAspRelWeight'">
                            </mat-form-field>
                        </div>

                    </div>
                </div>

            </div>
        </div>

    </form>

</div>

<mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="curriculumNum">
        <mat-header-cell *matHeaderCellDef> Curr # </mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-2xl font-bold"> {{element.curriculumNum}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="phaseName">
        <mat-header-cell *matHeaderCellDef> Phase Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.phaseName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="phaseNum">
        <mat-header-cell *matHeaderCellDef> Phase # </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.phaseNum}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="aspectGroupName">
        <mat-header-cell *matHeaderCellDef> AspectGroup </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.aspectGroupName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="aspectGroupNum">
        <mat-header-cell *matHeaderCellDef> AG # </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.aspectGroupNum}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="aspectName">
        <mat-header-cell *matHeaderCellDef> Aspect </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.aspectName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="scenarioTitle">
        <mat-header-cell *matHeaderCellDef> Scenario </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.scenarioTitle}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="scenAspRelType">
        <mat-header-cell *matHeaderCellDef> RelType </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.scenAspRelType}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="scenAspRelLength">
        <mat-header-cell *matHeaderCellDef> Length </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.scenAspRelLength}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="scenAspRelWeight">
        <mat-header-cell *matHeaderCellDef> Weight </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.scenAspRelWeight}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="level">
        <mat-header-cell *matHeaderCellDef> Level </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.level}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="priority">
        <mat-header-cell *matHeaderCellDef> Priority </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.priority}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="practice">
        <mat-header-cell *matHeaderCellDef> Practice </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.practice}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="isRecommendable">
        <mat-header-cell *matHeaderCellDef> Rec? </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.isRecommendable}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef> Edit </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <button class="px-3 mx-3"
                    mat-flat-button
                    [color]="'primary'" (click)="EditCurriculum(element)">
                <span>Edit</span>
            </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
</mat-table>

