import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SMService } from '../../../services/sm.service';
import { ILoadResult } from '../../../models/ILoadResult';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Aspect } from '../../../models/Aspect';
import { FuseAlertType } from '@fuse/components/alert';
import { fuseAnimations } from '@fuse/animations';
import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
export enum SelectType {
    single,
    multiple
}

@Component({
  selector: 'app-adminaspects',
    templateUrl: './adminaspects.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class AdminAspectsComponent implements OnInit {
    baseUrl = '';
    public theURL: string;
    public thehttp: HttpClient;
    public loadResults: ILoadResult[];
    public currentResult: string;
    public currentUser: string = "";
    public smService: SMService;
    public aspectDetailForm: FormGroup;

    public PageTitle: string = "Aspects Admin";

    public aspects: Aspect[];

    public selectedItem: Aspect;
    public showItemDetail: boolean = false;


    visibleTab: string = "";

    displayedColumns: string[] = [
        "newAspect",
        "name",
        "id",
        "engStart",
        "engEarly",
        "aspectType",
        "details"
    ];

    selectType = [
        { text: "Single", value: SelectType.single },
        { text: "Multiple", value: SelectType.multiple }
    ];

    dataSource = new MatTableDataSource<Aspect>();
    selection = new SelectionModel<Aspect>(true, []);
    displayType = SelectType.multiple;

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: 'Successfully updated the aspect.'
    };
    showAlert: boolean = false;


    constructor(
        http: HttpClient,
        private formBuilder: FormBuilder,
        sms: SMService
        )
    {
        this.baseUrl = environment.BASE_URL;
        this.theURL = this.baseUrl;
        this.thehttp = http;
        this.smService = sms;
    }

    selectHandler(row: Aspect) {
        if (this.displayType == SelectType.single) {
            if (!this.selection.isSelected(row)) {
                this.selection.clear();
            }
        }
        this.selection.toggle(row);
    }

    onChange(typeValue: number) {
        this.displayType = typeValue;
        this.selection.clear();
    }


    ngOnInit(): void {
        // Get the List of Aspects in the Graph Database
        this.smService.GetAdminAspects().subscribe(result => {

            this.aspects = result.aspects;
            this.dataSource = new MatTableDataSource<Aspect>(this.aspects);

        }, error => console.error(error));
    }

    CloseItem() {
        this.showItemDetail = false;
    }

    ShowItemDetail(item) {
        this.selectedItem = item;
        this.showAlert = false;

        this.aspectDetailForm = this.formBuilder.group({
            newAspect: [this.selectedItem.newAspect],
            name: [this.selectedItem.name],
            description: [this.selectedItem.description],
            aspectType: [this.selectedItem.aspectType],
            moneyBudget: [this.selectedItem.moneyBudget],
            hrsBudget: [this.selectedItem.hrsBudget],
            engStart: [this.selectedItem.engStart],
            engEarly: [this.selectedItem.engEarly]
        });

        this.showItemDetail = true;

    }

    AddNewItem() {
        console.log("Adding new aspect");

        // Create a new aspect
        this.selectedItem = new Aspect();
        this.showAlert = false;
        this.selectedItem.id = NIL_UUID; // Of format '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'

        console.log("Aspect is: " + this.selectedItem.name + " " + this.selectedItem.description + " "
            + this.selectedItem.aspectType + " " + this.selectedItem.moneyBudget + " " + this.selectedItem.hrsBudget + " "
            + this.selectedItem.engStart + " " + this.selectedItem.engEarly);

        this.aspectDetailForm = this.formBuilder.group({
            newAspect: [true],
            name: ['New aspect'],
            description: ['New aspect description'],
            aspectType: ['aspect'],
            moneyBudget: ['0'],
            hrsBudget: ['0'],
            engStart: ['0'],
            engEarly: ['0']
        });

        this.showItemDetail = true;

    }

    DeleteItem() {
        console.log("Deleting aspect " + this.selectedItem.name);

        // Delete the aspect
        var aspectId = this.selectedItem.id;
        this.smService.DeleteAspect(aspectId).subscribe(result => {

            if (result == null || result == false || result == undefined) {
                console.log("Unable to delete aspect.");
                this.alert = {
                    type: 'error',
                    message: 'Unable to define aspect.  Please try again.'
                };
            }

            // Remove the aspect from the list
            this.aspects.splice(this.aspects.indexOf(this.selectedItem), 1);

            // Update the table
            this.dataSource = new MatTableDataSource<Aspect>(this.aspects);
            this.showAlert = true;

        }, error => console.error(error));

    }

    SaveItem() {
        
        // Update the selectedItem with the form values
        this.selectedItem.newAspect = this.aspectDetailForm.value.newAspect;
        console.log("Saving aspect " + this.selectedItem.newAspect);

        // To handle the boolean value of newAspect - form value is a string
        if (this.aspectDetailForm.value.newAspect == "true") {
            this.selectedItem.newAspect = true;
        } else {
            this.selectedItem.newAspect = false;
        }
        console.log("Saving aspect " + this.selectedItem.newAspect);

        this.selectedItem.name = this.aspectDetailForm.value.name;
        this.selectedItem.description = this.aspectDetailForm.value.description;
        this.selectedItem.aspectType = this.aspectDetailForm.value.aspectType;
        this.selectedItem.moneyBudget = this.aspectDetailForm.value.moneyBudget;
        this.selectedItem.hrsBudget = this.aspectDetailForm.value.hrsBudget;
        this.selectedItem.engStart = this.aspectDetailForm.value.engStart;
        this.selectedItem.engEarly = this.aspectDetailForm.value.engEarly;

        // Save the aspect
        this.smService.UpdateAspect(this.selectedItem).subscribe(result => {

            if (result == null) {
                console.log("Unable to update aspect.");
                this.alert = {
                    type: 'error',
                    message: 'Unable to update aspect.  Please try again.'
                };
            } 

            // Add the new aspect to the list
            this.selectedItem = result;
            this.aspects.push(result);

            // Update the table
            this.dataSource = new MatTableDataSource<Aspect>(this.aspects);
            this.showAlert = true;


        }, error => console.error(error));



    }
}

