<div class="w-full">

    <div class="flex flex-row w-full items-center">
        <div><p class="text-lg font-semibold p-1">Sign Up Steps</p>
        </div>
        <div class="grow">
            <mat-stepper [linear]="false" #stepper class="mb-2" [selectedIndex]="stepIndex">
                <mat-step>
                    <form [formGroup]="firstFormGroup">
                        <ng-template matStepLabel>
                            <div class="text-lg">Profile</div>
                            <div class="text-sm stepSubLabel">Setup your learning objectives</div>
                        </ng-template>
                    </form>
                </mat-step>
                <mat-step>
                    <form [formGroup]="secondFormGroup">
                        <ng-template matStepLabel>
                            <div class="text-lg">Program</div>
                            <div class="text-sm stepSubLabel">Get your customized lessons</div>
                        </ng-template>
                    </form>
                </mat-step>

                <!--<mat-step>
                    <form [formGroup]="thirdFormGroup">
                        <ng-template matStepLabel>
                            <div class="text-lg">Scenarios</div>
                            <div class="text-sm stepSubLabel">Watch scenario content</div>
                        </ng-template>
                    </form>
                </mat-step>-->

                <mat-step>
                    <form [formGroup]="fourthFormGroup">
                        <ng-template matStepLabel>
                            <div class="text-lg">Register</div>
                            <div class="text-sm stepSubLabel">For security and privacy</div>
                        </ng-template>
                    </form>
                </mat-step>

                <mat-step>
                    <form [formGroup]="fifthFormGroup">
                        <ng-template matStepLabel>
                            <div class="text-lg">Response</div>
                            <div class="text-sm stepSubLabel">Submit your video response</div>
                        </ng-template>
                    </form>
                </mat-step>

                <mat-step>
                    <form [formGroup]="sixthFormGroup">
                        <ng-template matStepLabel>
                            <div class="text-lg">Feedback</div>
                            <div class="text-sm stepSubLabel">Get a personalized evaluation</div>
                        </ng-template>
                    </form>
                </mat-step>

            </mat-stepper>
        </div>
    </div>
</div>







