import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ProgramMessageModule } from './programmessage/programmessage.module';
import { ProgramStreamComponent } from './programstream.component';
import { VideoUploaderModule } from './videouploader/videouploader.module';


/*const exampleRoutes: Route[] = [
    {
        path     : '',
        component: ProgramStreamComponent
    }
];*/

@NgModule({
    declarations: [
        ProgramStreamComponent
    ],
    imports     : [
        //RouterModule.forChild(exampleRoutes),
        SharedModule,
        DashboardModule,
        ProgramMessageModule,
        VideoUploaderModule
    ],
    exports: [
        ProgramStreamComponent
    ]
})
export class ProgramStreamModule
{
}
